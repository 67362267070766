import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchableDropdown } from 'elements/Inputs/FormInput/SearchableDropdown';
import { Colors } from 'helpers/Colors';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { setSelectedTRF, resetTestPointVal } from 'journeys/portal/Forms/Forms.actions';
import { setCurrentView } from 'journeys/portal/Home/Home.actions';
import { clearSRAData } from 'journeys/portal/Forms/FormsP8.actions';
import { SelectTrfText, SelectDropdown, SelectArrow, DropDownStyle } from './Footer.styled';

const TrfDropdownSelection = (props) => {
    const { settingSelectedTRF, trfMasterList, setCurrentAppView, resetSRAData, workType, clearTestPointValue } = props;
    const activeTRFId = trfMasterList.filter(x => x.Status === 'Active' && x.TrfShortDesc !== 'Not In MRAT'
        && x.WorkType.toLowerCase() === workType.toLowerCase());
    const [selectTrfId, setSelectedTrfId] = useState('');
    const actionButtonHandler = () => {
        settingSelectedTRF(selectTrfId);
        clearTestPointValue();
        resetSRAData();
        setCurrentAppView(selectTrfId.trim());
    };
    const formHandler = (obj) => {
        setSelectedTrfId(trfMasterList.find(x => x.TrfShortDescReplaceWhenActive === obj?.trfMasterList)?.TrfId);
    };
    return (
        <div style={{ position: 'relative' }}>
            <div className="container">
                <div>
                    <SelectTrfText>
                        Select TRF
                    </SelectTrfText>
                </div>
                <DropDownStyle>
                    <SelectDropdown>
                        <SearchableDropdown
                            width="224px"
                            height="2rem"
                            name="trfMasterList"
                            value={activeTRFId.find(x => x.TrfId === selectTrfId)?.TrfShortDescReplaceWhenActive || ''}
                            handleChange={formHandler}
                            placeholder="Search TRF"
                            options={activeTRFId
                                .map(x => ({ name: x.TrfShortDescReplaceWhenActive, value: x.TrfShortDescReplaceWhenActive }))}
                            alignItems="flex-start"
                            flexDirection="column"
                            selectMarginLeft="15px"
                        />
                    </SelectDropdown>
                    <SelectArrow>
                        <IconButton
                            disabled={!selectTrfId}
                            component="span"
                            onClick={() => actionButtonHandler()}
                            onChange={formHandler}
                            style={{ color: selectTrfId ? Colors.green : Colors.grey }}
                        >
                            <ArrowForwardIosIcon
                                id="ActionButtonArrow"
                                style={{ fontSize: '33px' }}
                            />
                        </IconButton>
                    </SelectArrow>
                </DropDownStyle>
            </div>
        </div>
    );
};

TrfDropdownSelection.propTypes = {
    clearTestPointValue: PropTypes.func,
    resetSRAData: PropTypes.func,
    setCurrentAppView: PropTypes.func,
    settingSelectedTRF: PropTypes.func,
    trfMasterList: PropTypes.array,
    workType: PropTypes.string
};

const mapStateToProps = ({ Home, Forms }) => ({
    workType: Home.selectedWorkType,
    userRole: Home.userRole,
    trfMasterList: Home.trfMasterList,
    formData: Forms.formData
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    settingSelectedTRF: (selectedTrfId) => dispatch(setSelectedTRF(selectedTrfId)),
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    resetSRAData: () => dispatch(clearSRAData()),
    clearTestPointValue: () => dispatch(resetTestPointVal())
});
export default connect(mapStateToProps, mapDispatchToProps)(TrfDropdownSelection);
