import {
    AsFoundTestTableSectionData, AsFoundTestTableSectionDataP72,
    AsFoundTestTableSectionDataP75, AsFoundTestTableSectionDataP73, AsFoundTestTableSectionDataP77,
    AsFoundTestTableSectionDataP67P841P8101, AsFoundTestTableSectionDataP710andP68,
    AsFoundTestTableSectionDataP63andP632, AsFoundTestTableSectionDataP61andP612, AsFoundTestTableSectionDataP62,
    AsFoundTestTableSectionDataP65andP831, AsFoundTestTableSectionDataP64andP642, AsFoundTestTableSectionDataP66,
    AsFoundTestTableSectionDataP610, AsFoundTestTableSectionDataP851,
    AsFoundTestTableSectionDataM613, AsFoundTestTableSectionDataM67, AsFoundTestTableSectionDataP861P69,
    AsFoundTestTableSectionDataM68, AsFoundTestTableSectionDataM63, AsFoundTestTableSectionDataM612, AsFoundTestTableSectionDataM64,
    AsFoundTestTableSectionDataM69, AsFoundTestTableSectionDataM610, AsFoundTestTableSectionDataM611M614M615M61, AsFoundTestTableSectionDataM66,
    AsFoundTestTableSectionDataM65, AsFoundTestTableSectionDataM31, AsFoundTestTableSectionDataP611,
    AsFoundTestTableSectionDataP724
} from './Data';
import { AsFoundTestTableSectionDataP871 } from './Data/P871/P871.data';
import { AsFoundTestTableSectionDataP716 } from './Data/P716/P716.data';
import { AsFoundTestTableSectionDataP723 } from './Data/P723/P723.data';
import { AsFoundTestTableSectionDataP728 } from './Data/P728/P728.data';
import { AsFoundTestTableSectionDataC11C13C132C14 } from './Data/C11/C11.data';
import { AsFoundTestTableSectionDataP727 } from './Data/P727/P727.data';
import { AsFoundTestTableSectionDataP615 } from './Data/P615/P615.data';
import { AsFoundTestTableSectionDataP732 } from './Data/P732/P732.data';
import { AsFoundTestTableSectionDataP891 } from './Data/P891/P891.data';
import { AsFoundTestTableSectionDataP726 } from './Data/P726/P726.data';
import { AsFoundTestTableSectionDataP725 } from './Data/P725/P725.data';
import { AsFoundTestTableSectionDataP733P620 } from './Data/P733/P733.data';
import { AsFoundTestTableSectionDataP8111 } from './Data/P8111/P8111.data';
import { AsFoundTestTableSectionDataM616 } from './Data/M616/M616.data';
import { AsFoundTestTableSectionDataM71 } from './Data/M71/M71.data';
import { AsFoundTestTableSectionDataP720 } from './Data/P720/P720.data';
import { AsFoundTestTableSectionDataP721 } from './Data/P721/P721.data';
import { AsFoundTestTableSectionDataP711 } from './Data/P711/P711.data';
import { AsFoundTestTableSectionDataP8141 } from './Data/P8141/P8141.data';
import { AsFoundTestTableSectionDataP6022 } from './Data/P6022/P6022.data';
import { AsFoundTestTableSectionDataS72 } from './Data/S72/S72.data';
import { AsFoundTestTableSectionDataS71 } from './Data/S71/S71.data';

export const selectAsFoundTestTableSectionData = (selectedTag, formName, formData, aftTestPoint) => {
    const formNameToFunctionMap = {
        'P7-1': () => AsFoundTestTableSectionData(selectedTag, formData),
        'P7-2': () => AsFoundTestTableSectionDataP72(selectedTag, formData),
        'P7-3': () => AsFoundTestTableSectionDataP73(selectedTag, formData),
        'P7-5': () => AsFoundTestTableSectionDataP75(selectedTag, formData),
        'P7-7': () => AsFoundTestTableSectionDataP77(selectedTag, formData),
        'P7-9': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-18': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-7': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-4.1': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-10.1': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-13': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-16': () => AsFoundTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-10': () => AsFoundTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-8': () => AsFoundTestTableSectionDataP710andP68(selectedTag, formData),
        'P7-8': () => AsFoundTestTableSectionDataP710andP68(selectedTag, formData),
        'P7-12': () => AsFoundTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-17': () => AsFoundTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-10': () => AsFoundTestTableSectionDataP610(selectedTag, formData),
        'P7-6': () => AsFoundTestTableSectionDataP610(selectedTag, formData),
        'P6-4': () => AsFoundTestTableSectionDataP64andP642(selectedTag, formData),
        'P6-4.2': () => AsFoundTestTableSectionDataP64andP642(selectedTag, formData),
        'P6-5': () => AsFoundTestTableSectionDataP65andP831(selectedTag, formData),
        'P6-5.2': () => AsFoundTestTableSectionDataP65andP831(selectedTag, formData),
        'P8-3.1': () => AsFoundTestTableSectionDataP65andP831(selectedTag, formData),
        'P6-2': () => AsFoundTestTableSectionDataP62(selectedTag, formData),
        'P6-2.2': () => AsFoundTestTableSectionDataP62(selectedTag, formData),
        'P8-2.1': () => AsFoundTestTableSectionDataP62(selectedTag, formData),
        'P6-1': () => AsFoundTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-1.2': () => AsFoundTestTableSectionDataP61andP612(selectedTag, formData),
        'P8-1.1': () => AsFoundTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-3': () => AsFoundTestTableSectionDataP63andP632(selectedTag, formData),
        'P6-3.2': () => AsFoundTestTableSectionDataP63andP632(selectedTag, formData),
        'P7-4': () => AsFoundTestTableSectionDataP63andP632(selectedTag, formData),
        'P7-4.1': () => AsFoundTestTableSectionDataP63andP632(selectedTag, formData),
        'P6-6': () => AsFoundTestTableSectionDataP66(selectedTag, formData),
        'P8-17.1': () => AsFoundTestTableSectionDataP66(selectedTag, formData),
        'P8-6.1': () => AsFoundTestTableSectionDataP861P69(selectedTag, formData),
        'P6-9': () => AsFoundTestTableSectionDataP861P69(selectedTag, formData),
        'P8-5.1': () => AsFoundTestTableSectionDataP851(selectedTag, formData),
        'M6-13': () => AsFoundTestTableSectionDataM613(selectedTag, formData),
        'M6-7': () => AsFoundTestTableSectionDataM67(selectedTag, formData),
        'M6-8': () => AsFoundTestTableSectionDataM68(selectedTag, formData),
        'M6-9': () => AsFoundTestTableSectionDataM69(selectedTag, formData),
        'M6-6': () => AsFoundTestTableSectionDataM66(selectedTag, formData),
        'M6-10': () => AsFoundTestTableSectionDataM610(formData, aftTestPoint),
        'M6-11': () => AsFoundTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-14': () => AsFoundTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-15': () => AsFoundTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-1': () => AsFoundTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-3': () => AsFoundTestTableSectionDataM63(selectedTag, formData),
        'M6-4': () => AsFoundTestTableSectionDataM64(selectedTag, formData),
        'M6-12': () => AsFoundTestTableSectionDataM612(selectedTag, formData),
        'M6-5': () => AsFoundTestTableSectionDataM65(selectedTag, formData),
        'M3-1': () => AsFoundTestTableSectionDataM31(selectedTag, formData),
        'P6-11': () => AsFoundTestTableSectionDataP611(selectedTag, formData),
        'P7-17': () => AsFoundTestTableSectionDataP611(selectedTag, formData),
        'P8-7.1': () => AsFoundTestTableSectionDataP871(selectedTag, formData),
        'P7-23': () => AsFoundTestTableSectionDataP723(selectedTag, formData),
        'P7-16': () => AsFoundTestTableSectionDataP716(selectedTag, formData),
        'P6-13': () => AsFoundTestTableSectionDataP716(selectedTag, formData),
        'P7-33': () => AsFoundTestTableSectionDataP733P620(selectedTag, formData),
        'P6-20': () => AsFoundTestTableSectionDataP733P620(selectedTag, formData),
        'P7-25': () => AsFoundTestTableSectionDataP725(selectedTag, formData),
        'P7-26': () => AsFoundTestTableSectionDataP726(selectedTag, formData),
        'P7-28': () => AsFoundTestTableSectionDataP728(selectedTag, formData),
        'P7-24': () => AsFoundTestTableSectionDataP724(selectedTag, formData),
        'C1-1': () => AsFoundTestTableSectionDataC11C13C132C14(selectedTag, formData),
        'C1-3': () => AsFoundTestTableSectionDataC11C13C132C14(selectedTag, formData),
        'C1-3.2': () => AsFoundTestTableSectionDataC11C13C132C14(selectedTag, formData),
        'C1-4': () => AsFoundTestTableSectionDataC11C13C132C14(selectedTag, formData),
        'P7-27': () => AsFoundTestTableSectionDataP727(selectedTag, formData),
        'P6-15': () => AsFoundTestTableSectionDataP615(selectedTag, formData),
        'P7-32': () => AsFoundTestTableSectionDataP732(selectedTag, formData),
        'P8-9.1': () => AsFoundTestTableSectionDataP891(selectedTag, formData),
        'P7-19': () => AsFoundTestTableSectionDataP891(selectedTag, formData),
        'P8-11.1': () => AsFoundTestTableSectionDataP8111(selectedTag, formData),
        'M6-16': () => AsFoundTestTableSectionDataM616(selectedTag, formData),
        'M7-1': () => AsFoundTestTableSectionDataM71(formData),
        'P7-20': () => AsFoundTestTableSectionDataP720(selectedTag, formData, aftTestPoint),
        'P7-21': () => AsFoundTestTableSectionDataP721(selectedTag, formData, aftTestPoint),
        'P7-11': () => AsFoundTestTableSectionDataP711(selectedTag, formData),
        'P8-13.1': () => AsFoundTestTableSectionDataP711(selectedTag, formData),
        'P8-14.1': () => AsFoundTestTableSectionDataP8141(formData),
        'P6-22': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-22': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-15': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-14': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-18': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P6-12': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-29': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P8-8.1': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P8-12.1': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'P7-30': () => AsFoundTestTableSectionDataP6022(selectedTag, formName, formData),
        'S7-2': () => AsFoundTestTableSectionDataS72(selectedTag, formName, formData),
        'S7-3': () => AsFoundTestTableSectionDataS72(selectedTag, formName, formData),
        'S7-4': () => AsFoundTestTableSectionDataS72(selectedTag, formName, formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Partial') {
        return AsFoundTestTableSectionDataS71(formData);
    }
    return filterViewData;
};
