import { FieldHasValue } from 'helpers/Validation';
import { VisualInspectSectionDataP77 } from './Data/P77/P77.data';
import { VisualInspectSectionDataP78 } from './Data/P78/P78.data';
import { VisualInspectSectionDataP710andP68 } from './Data/P710/P710.data';
import { VisualInspectSectionDataP610 } from './Data/P610/P610.data';
import { VisualInspectSectionDataP64andP642 } from './Data/P64/P64.data';
import { VisualInspectSectionDataP62 } from './Data/P62/P62.data';
import { VisualInspectSectionDataP61andP612 } from './Data/P61/P61.data';
import { VisualInspectSectionDataP63andP632 } from './Data/P63/P63.data';
import { VisualInspectSectionDataP622 } from './Data/P622/P622.data';
import { VisualInspectSectionDataP66 } from './Data/P66/P66.data';
import { VisualInspectSectionDataP811 } from './Data/P811/P811.data';
import { VisualInspectSectionDataP861P69 } from './Data/P69/P69.data';
import { VisualInspectSectionDataM67 } from './Data/M67/M67.data';
import { VisualInspectSectionDataP851 } from './Data/P851/P851.data';
import { VisualInspectSectionDataM68 } from './Data/M68/M68.data';
import { VisualInspectSectionDataM69 } from './Data/M69/M69.data';
import { VisualInspectSectionDataM610 } from './Data/M610/M610.data';
import { VisualInspectSectionDataM611M614M615 } from './Data/M611/M611.data';
import { VisualInspectSectionDataM63 } from './Data/M63/M63.data';
import { VisualInspectSectionDataM64 } from './Data/M64/M64.data';
import { VisualInspectSectionDataM612 } from './Data/M612/M612.data';
import { VisualInspectSectionDataM66 } from './Data/M66/M66.data';
import { VisualInspectSectionDataM65 } from './Data/M65/M65.data';
import { VisualInspectSectionDataM31 } from './Data/M31/M31.data';
import { VisualInspectSectionDataP611 } from './Data/P611/P611.data';
import { VisualInspectSectionDataP723 } from './Data/P723/P723.data';
import { VisualInspectSectionDataP8101 } from './Data/P8101/P8101.data';
import { VisualInspectSectionDataP716 } from './Data/P716/P716.data';
import { VisualInspectSectionDataP724 } from './Data/P724/P724.data';
import { VisualInspectSectionDataPP617 } from './Data/P617/P617.data';
import { VisualInspectSectionDataP6142 } from './Data/P6142/P6142.data';
import { VisualInspectSectionDataP728 } from './Data/P728/P728.data';
import { VisualInspectSectionDataC11C13C132C14 } from './Data/C11/C11.data';
import { VisualInspectSectionDataP727 } from './Data/P727/P727.data';
import { VisualInspectSectionDataP615 } from './Data/P615/P615.data';
import { VisualInspectSectionDataP732 } from './Data/P732/P732.data';
import { VisualInspectSectionDataP891 } from './Data/P891/P891.data';
import { VisualInspectSectionDataP733P620 } from './Data/P733/P733.data';
import { VisualInspectSectionDataP726 } from './Data/P726/P726.data';
import { VisualInspectSectionDataP8111 } from './Data/P8111/P8111.data';
import { VisualInspectSectionDataM616 } from './Data/M616/M616.data';
import { VisualInspectSectionDataM71 } from './Data/M71/M71.data';
import { VisualInspectSectionDataP720 } from './Data/P720/P720.data';
import { VisualInspectSectionDataP721 } from './Data/P721/P721.data';
import { VisualInspectSectionDataP711 } from './Data/P711/P711.data';
import { VisualInspectSectionDataP8141 } from './Data/P8141/P8141.data';
import { VisualInspectSectionDataP618 } from './Data/P618/P618.data';
import { VisualInspectSectionDataS71 } from './Data/S71/S71.data';

const F31FormulaCalculatedFailPassFormula = (formData) => {
    const FormulaCalculatedFPFormula = (formData.F30FloatPolarityStrengthCheck >= 50 ? 'PASS' : 'FAIL');
    return FieldHasValue(formData.F30FloatPolarityStrengthCheck) ? FormulaCalculatedFPFormula : '';
};
const visualLabel = 'VISUAL INSPECTION';
const retrieveLabel = 'Retrieve diagnostic message';
export const paddingTopVal = '15px 0 0 0';

export const VisualInspectSectionData = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visual inspect instrument tagging, level indicator in good condition, 
                 instrument wiring connection/termination, instrument condition, general deteriration and no leak.`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F30FloatPolarityStrengthCheck',
                label: 'Float Polarity Strength/Gauss Reading',
                value: formData.F30FloatPolarityStrengthCheck,
                isRequired: true,
                type: 'number',
                xs: 9,
                labelWidth: '67.2%',
                width: '32.8%',
                textAlign: 'center',
                marginRight: '1rem',
                marginTop: '12px',
                maxLength: '10',
                marginBottom: '12px'
            },
            {
                key: 'F31FormulaCalculatedFailPass',
                value: F31FormulaCalculatedFailPassFormula(formData),
                xs: 3,
                fontWeight: 'bold',
                width: '100%',
                fontSize: '9pt',
                labelWidth: '0',
                marginTop: '12px',
                borderLeft: '0',
                textAlign: 'center',
                readOnly: true,
                marginBottom: '12px'
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F32RetrieveDiagnosticMsg',
                    label: retrieveLabel,
                    value: formData.F32RetrieveDiagnosticMsg,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit'
                }]
        }
    ]

});
export const VisualInspectSectionDataP72 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect instrument tagging, tubing/fittings/ manifold integrity, 
                instrument wiring connection/ terminations, instrument condition, general deterioration and no leak.`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F32RetrieveDiagnosticMsg',
                    label: retrieveLabel,
                    value: formData.F32RetrieveDiagnosticMsg,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    marginTop: '12px',
                    alignItems: 'inherit',
                }]
        }
    ]

});
export const VisualInspectSectionDataP73 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect instrument tagging, tubing/fitting/ manifold integrity, 
                instrument wiring connection/ terminations, instrument condition, general deterioration and no leak.`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F32RetrieveDiagnosticMsg',
                    label: retrieveLabel,
                    value: formData.F32RetrieveDiagnosticMsg,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    marginTop: '12px',
                    alignItems: 'inherit',
                }]
        }
    ]

});
export const VisualInspectSectionDataP74 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect instrument tagging,
                instrument wiring connection/terminations,
                instrument condition, no excessive strain on cable for remote mount,
                thermowell with vent port, general deterioration and no leak sign`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F24RetrieveDiagnosticMessage',
                    label: 'Retrieve Diagnostic Message',
                    value: formData.F24RetrieveDiagnosticMessage,
                    isRequired: true,
                    isMultiline: true,
                    width: '50%',
                    xs: 12,
                    inputWidth: '50%',
                    marginTop: '11px',
                    alignItems: 'inherit'
                }]
        }
    ]
});

export const VisualInspectSectionDataP75 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F29VisuallyInspectInstrumentTaggingNote',
            label: `Visually inspect instrument tagging, instrument wiring connection/terminations, instrument condition, 
                no excessive strain on cable for remote mount, thermowell with vent port, general deterioration and no leak.`,
            value: formData.F29VisuallyInspectInstrumentTaggingNote,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F32RetrieveDiagnosticMsg',
            label: retrieveLabel,
            value: formData.F32RetrieveDiagnosticMsg,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px'
        }]
    }]
});

export const VisualInspectSectionDataP79 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'VISUALINSPECTION',
                label: visualLabel,
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: paddingTopVal
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F179VisuallyInspection',
                label: `Visually inspect general instrument condition, state of instrument wiring/termination/glanding, 
                impulse lines/tubing, instrument tagging, signs of corrosion, deterioration, any process fluid leaks, etc.`,
                value: formData.F179VisuallyInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});

export const VisualInspectSectionDataP712 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'VISUALINSPECTION',
                label: visualLabel,
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: paddingTopVal
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F119Visualinspection',
                label: `Visually inspect instrument tagging, instrument mounting conditions, whether the level indicator is in 
                good condition (if used, ensure site glass is clear and/or flippers are in good condition and functional), the state of 
                instrument wiring connection/terminations, the general instrument condition, for general signs deterioration as well as 
                for signs of process fluid weeping/leaking.`,
                value: formData.F119Visualinspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'DIAGNOSTICS',
                label: 'DIAGNOSTICS',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '0'
            }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F120Diagnostics',
                    label: 'Retrieve Diagnostic Message Information:',
                    value: formData.F120Diagnostics,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit'
                }]
        }
    ]

});

export const VisualInspectSectionDataP67P841 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'Nodata',
                isLabel: true,
                xs: 12,
                padding: paddingTopVal
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F179VisuallyInspection',
                label: `Visually inspect physical device for signs of damage: ID tags present, corrosion, moisture incursion, 
                inspect wiring and conduit.`,
                value: formData.F179VisuallyInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});

export const VisualInspectSectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F119VisualInspection',
                label: `Visually inspect damper tagging,  wiring connection/terminations, damper condition, pressure regulator 
                setting and condition, quarter turn indication position correct if applicable, bug screen orientation with no blockage, 
                general deterioration and no leak sign`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});

export const selectVisualInspectionTemplateForForm = (selectedTag, formName, formData) => {
    const formNameToFunctionMap = {
        'P7-1': () => VisualInspectSectionData(selectedTag, formData),
        'P7-2': () => VisualInspectSectionDataP72(selectedTag, formData),
        'P7-3': () => VisualInspectSectionDataP73(selectedTag, formData),
        'P7-4': () => VisualInspectSectionDataP74(selectedTag, formData),
        'P7-4.1': () => VisualInspectSectionDataP74(selectedTag, formData),
        'P7-5': () => VisualInspectSectionDataP75(selectedTag, formData),
        'P7-28': () => VisualInspectSectionDataP728(selectedTag, formData),
        'P7-7': () => VisualInspectSectionDataP77(selectedTag, formData),
        'P6-5': () => VisualInspectSectionDataP77(selectedTag, formData),
        'P6-5.2': () => VisualInspectSectionDataP77(selectedTag, formData),
        'P8-3.1': () => VisualInspectSectionDataP77(selectedTag, formData),
        'P7-8': () => VisualInspectSectionDataP78(selectedTag, formData),
        'P7-9': () => VisualInspectSectionDataP79(selectedTag, formData),
        'P6-7': () => VisualInspectSectionDataP67P841(selectedTag, formData),
        'P8-4.1': () => VisualInspectSectionDataP67P841(selectedTag, formData),
        'P8-10.1': () => VisualInspectSectionDataP8101(selectedTag, formData),
        'P7-13': () => VisualInspectSectionDataP8101(selectedTag, formData),
        'P6-16': () => VisualInspectSectionDataP8101(selectedTag, formData),
        'P7-10': () => VisualInspectSectionDataP710andP68(selectedTag, formData),
        'P6-8': () => VisualInspectSectionDataP710andP68(selectedTag, formData),
        'P7-12': () => VisualInspectSectionDataP712(selectedTag, formData),
        'P6-10': () => VisualInspectSectionDataP610(selectedTag, formData),
        'P7-6': () => VisualInspectSectionDataP610(selectedTag, formData),
        'C1-2': () => VisualInspectSectionDataP610(selectedTag, formData),
        'P6-4': () => VisualInspectSectionDataP64andP642(selectedTag, formData),
        'P6-4.2': () => VisualInspectSectionDataP64andP642(selectedTag, formData),
        'P6-2': () => VisualInspectSectionDataP62(selectedTag, formName, formData),
        'P8-2.1': () => VisualInspectSectionDataP62(selectedTag, formName, formData),
        'P6-1': () => VisualInspectSectionDataP61andP612(selectedTag, formData),
        'P6-1.2': () => VisualInspectSectionDataP61andP612(selectedTag, formData),
        'P6-3': () => VisualInspectSectionDataP63andP632(selectedTag, formData),
        'P6-3.2': () => VisualInspectSectionDataP63andP632(selectedTag, formData),
        'P6-2.2': () => VisualInspectSectionDataP622(selectedTag, formData),
        'P6-6': () => VisualInspectSectionDataP66(selectedTag, formData),
        'P8-17.1': () => VisualInspectSectionDataP66(selectedTag, formData),
        'P8-5.1': () => VisualInspectSectionDataP851(selectedTag, formData),
        'P8-1.1': () => VisualInspectSectionDataP811(selectedTag, formName, formData),
        'P8-7.1': () => VisualInspectSectionDataP811(selectedTag, formName, formData),
        'P8-6.1': () => VisualInspectSectionDataP861P69(selectedTag, formData),
        'P6-9': () => VisualInspectSectionDataP861P69(selectedTag, formData),
        'M6-13': () => VisualInspectSectionDataM613(selectedTag, formData),
        'M6-7': () => VisualInspectSectionDataM67(selectedTag, formData),
        'M6-8': () => VisualInspectSectionDataM68(selectedTag, formData),
        'M6-9': () => VisualInspectSectionDataM69(selectedTag, formData),
        'M6-6': () => VisualInspectSectionDataM66(selectedTag, formData),
        'M6-10': () => VisualInspectSectionDataM610(selectedTag, formData),
        'M6-11': () => VisualInspectSectionDataM611M614M615(selectedTag, formData),
        'M6-14': () => VisualInspectSectionDataM611M614M615(selectedTag, formData),
        'M6-15': () => VisualInspectSectionDataM611M614M615(selectedTag, formData),
        'M6-1': () => VisualInspectSectionDataM611M614M615(selectedTag, formData),
        'M6-3': () => VisualInspectSectionDataM63(selectedTag, formData),
        'M6-4': () => VisualInspectSectionDataM64(selectedTag, formData),
        'M6-12': () => VisualInspectSectionDataM612(selectedTag, formData),
        'M6-5': () => VisualInspectSectionDataM65(selectedTag, formData),
        'M3-1': () => VisualInspectSectionDataM31(selectedTag, formData),
        'P6-11': () => VisualInspectSectionDataP611(selectedTag, formData),
        'P7-17': () => VisualInspectSectionDataP611(selectedTag, formData),
        'P7-16': () => VisualInspectSectionDataP716(selectedTag, formData),
        'P6-13': () => VisualInspectSectionDataP716(selectedTag, formData),
        'P7-23': () => VisualInspectSectionDataP723(selectedTag, formData),
        'P7-24': () => VisualInspectSectionDataP724(selectedTag, formData),
        'P7-26': () => VisualInspectSectionDataP726(selectedTag, formData),
        'P7-25': () => VisualInspectSectionDataP726(selectedTag, formData),
        'P7-27': () => VisualInspectSectionDataP727(selectedTag, formData),
        'P6-17': () => VisualInspectSectionDataPP617(selectedTag, formData),
        'P6-14.2': () => VisualInspectSectionDataP6142(formName, formData),
        'P6-14': () => VisualInspectSectionDataP6142(formName, formData),
        'P7-31': () => VisualInspectSectionDataP6142(formName, formData),
        'P6-23.2': () => VisualInspectSectionDataP6142(formName, formData),
        'C1-1': () => VisualInspectSectionDataC11C13C132C14(selectedTag, formData),
        'C1-3': () => VisualInspectSectionDataC11C13C132C14(selectedTag, formData),
        'C1-3.2': () => VisualInspectSectionDataC11C13C132C14(selectedTag, formData),
        'C1-4': () => VisualInspectSectionDataC11C13C132C14(selectedTag, formData),
        'P6-15': () => VisualInspectSectionDataP615(selectedTag, formData),
        'P7-32': () => VisualInspectSectionDataP732(selectedTag, formData),
        'P7-33': () => VisualInspectSectionDataP733P620(selectedTag, formData),
        'P6-20': () => VisualInspectSectionDataP733P620(selectedTag, formData),
        'P8-9.1': () => VisualInspectSectionDataP891(selectedTag, formData),
        'P7-19': () => VisualInspectSectionDataP891(selectedTag, formData),
        'P8-11.1': () => VisualInspectSectionDataP8111(selectedTag, formData),
        'M6-16': () => VisualInspectSectionDataM616(selectedTag, formData),
        'M7-1': () => VisualInspectSectionDataM71(selectedTag, formData),
        'P7-20': () => VisualInspectSectionDataP720(selectedTag, formData),
        'P7-21': () => VisualInspectSectionDataP721(selectedTag, formData),
        'P7-11': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P6-22': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-22': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-15': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-14': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-18': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P6-12': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-29': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P8-8.1': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P8-12.1': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P7-30': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P8-13.1': () => VisualInspectSectionDataP711(selectedTag, formName, formData),
        'P8-14.1': () => VisualInspectSectionDataP8141(formData),
        'P6-18': () => VisualInspectSectionDataP618(formData),
        'S7-1': () => VisualInspectSectionDataS71(formName, formData),
        'S7-2': () => VisualInspectSectionDataS71(formName, formData),
        'S7-3': () => VisualInspectSectionDataS71(formName, formData),
        'S7-4': () => VisualInspectSectionDataS71(formName, formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
