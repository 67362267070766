import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField, ShowIfDifferentThanZero, IsANumber } from 'helpers/Validation';

export const AsFoundTestTableF39HmiInst1Formula = (formData) => FieldHasValue(formData.F22HmiUrv) ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
    .concat(' ')
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ShowIfDifferentThanZero(ValidateField(formData.F26InstrumentUrv)))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%';

export const AsFoundTestTableF40HmiInst2Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv))
    ? `50% - ${ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
        + (ValidateNumberField(formData.F22HmiUrv)
            - ValidateNumberField(formData.F21HmiLrv))
        * (0.5))} ${ValidateField(formData.F24HmiEngineeringUnit)} - ${ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
            + (ValidateNumberField(formData.F26InstrumentUrv)
                - ValidateNumberField(formData.F25InstrumentLrv))
            * (0.5))} ${ValidateField(formData.F28InstrumentEngineeringUnit)}`
    : '50%';

export const AsFoundTestTableF41HmiInst3Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
    .concat(' ')
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ExponentialToDecimal(ShowIfDifferentThanZero(ValidateNumberField(formData.F25InstrumentLrv))))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit))
    : '0%');

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F42GaugeReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1)
            - ValidateNumberField(formData.F42GaugeReading1)));
    }
    return '';
};

export const AsFoundTestTableF55DeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F43GaugeReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2)
            - ValidateNumberField(formData.F43GaugeReading2)));
    }
    return '';
};

export const AsFoundTestTableF56DeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F44GaugeReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3)
            - ValidateNumberField(formData.F44GaugeReading3)));
    }
    return '';
};

export const FoundResultF61AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F42GaugeReading1)
        && FieldHasValue(formData.F43GaugeReading2)
        && FieldHasValue(formData.F44GaugeReading3)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3)
        && FieldHasValue(formData.F25InstrumentLrv) && IsANumber(formData.F25InstrumentLrv) && FieldHasValue(formData.F60ToleranceEu)) {
        return (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2),
        ValidateNumberField(formData.F56DeviationEu3)]) <= ValidateNumberField(formData.F60ToleranceEu)
            ? 'PASS' : 'FAIL');
    }
    return '';
};
