import React, { useState } from 'react';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import DataTable from 'components/DataTable/DataTable';
import { connect } from 'react-redux';
import { titleCaseFunction } from 'helpers/TitleCaseFunction';
import PropTypes from 'prop-types';
import { redTableCellStyle } from 'components/DataTable/DataTable.styled';
import { parseDate } from '../../../helpers/DateFormatter';
import DateSection from './DateSection/DateSection';
import { updatedTabledefination } from './MOSelection.constants';
import { setDataForTagSelectionMOScreen, sortMO } from './MOSelection.actions';
import { getSelectedTagsForTagMOScreenData } from '../TagSelectionMOScreen/TagSelectionMOScreen.actions';
import { moSelectionInitialState } from './MOSelection.data';
import _ from 'lodash';

const MOSelectionPage = (props) => {
  const { moDataAll, triggerSort, initialiseTagSelectionMOScreendata,
    selectedAssetName, selectedBarrierType,
    loadSelectedTagsForTagMOScreenData, startDate, finishDate,
    searchCriteria, searchedMoData, isTableLoading, selectedWorkType } = props;

  const [sortAscOrder, setSortAscOrder] = useState(false);
  const barrierTypeForFilter = (selectedBarrierType === 'all' && selectedWorkType.toLowerCase() === 'preventative')
    ? ['p7 sis', 'm6 f&g', 'p6 sri/src', 'p8 sra']
    : [selectedBarrierType];

  const data = _.isEqual(searchCriteria, moSelectionInitialState.searchCriteria) ? moDataAll : searchedMoData;

  const tableData = data.filter(
    item => barrierTypeForFilter.includes(item.BarrierType.toLowerCase())
      && item.BpSiteName?.toLowerCase() === selectedAssetName
      && new Date(`${item.ScheduleFinishDate}Z`).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)
      && new Date(`${item.ScheduleFinishDate}Z`).setHours(0, 0, 0, 0) <= new Date(finishDate).setHours(0, 0, 0, 0));

  const sortButtonHandler = (columnName) => {
    triggerSort(columnName, sortAscOrder);
    setSortAscOrder(!sortAscOrder);
  };
  const now = new Date();
  const { redTableCell } = redTableCellStyle();

  const getParsedTableData = () => tableData.map((item) => ({
    ...item,
    IsCellRedClassStartDate: (item.ScheduleStartDate && new Date(item.ScheduleStartDate).setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)),
    ScheduleStartDate: parseDate(`${item.ScheduleStartDate}Z`),
    ScheduleFinishDate: parseDate(`${item.ScheduleFinishDate}Z`),
    IsCellRedClassFinishDate: (item.ScheduleFinishDate && new Date(`${item.ScheduleFinishDate}Z`).setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0))
      ? redTableCell : null,

  }));

  const handleArrowButton = (rowData) => {
    initialiseTagSelectionMOScreendata(rowData);
    loadSelectedTagsForTagMOScreenData(rowData);
  };

  return (
    <SiteWrapper
      headerText={`MO Selection: ${titleCaseFunction(selectedWorkType)}`}
      showTotalCount
      totalCount={tableData.length}
      goBack="home"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <DateSection />
            <DataTable
              id="MOSelectionDataTable"
              tableDefinition={updatedTabledefination}
              tableData={getParsedTableData()}
              isLoading={isTableLoading}
              sortButtonHandler={sortButtonHandler}
              showArrowButton
              actionButtonHandler={(dataRow) => handleArrowButton(dataRow)}
              height="calc(100vh - 275px)"
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};

const mapStateToProps = ({ MOSelection, Home }) => ({
  isTableLoading: MOSelection.tableLoading,
  moDataAll: MOSelection.moDataAll,
  searchCriteria: MOSelection.searchCriteria,
  totalCount: MOSelection.totalCount,
  searchedMoData: MOSelection.searchedMoData,
  selectedAssetName: Home.selectedAssetName,
  selectedBarrierType: Home.selectedBarrierType,
  startDate: MOSelection.startDate,
  finishDate: MOSelection.endDate,
  tags: Home.tags,
  selectedWorkType: Home.selectedWorkType
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  initialiseTagSelectionMOScreendata: (rowData) => dispatch(setDataForTagSelectionMOScreen(rowData)),
  loadSelectedTagsForTagMOScreenData: (rowData) => dispatch(getSelectedTagsForTagMOScreenData(rowData)),
  triggerSort: (colForSorting, sortAscOrder) => dispatch(sortMO(colForSorting, sortAscOrder)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MOSelectionPage);

MOSelectionPage.propTypes = {
  finishDate: PropTypes.any,
  initialiseTagSelectionMOScreendata: PropTypes.func,
  isTableLoading: PropTypes.bool,
  loadSelectedTagsForTagMOScreenData: PropTypes.func,
  moDataAll: PropTypes.array,
  searchCriteria: PropTypes.object,
  searchedMoData: PropTypes.array,
  selectedAssetName: PropTypes.string,
  selectedBarrierType: PropTypes.string,
  selectedWorkType: PropTypes.string,
  startDate: PropTypes.any,
  triggerSort: PropTypes.func
};
