import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { AsFoundResultFormulaS71 } from './S71.formulas';

export const asFoundSolenoid = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'AsFoundTestLabel',
                    label: 'As-Found Test (1st solenoid/positioner)',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 4px'
                },
                {
                    key: 'F965AftValveFullClosureTravelTimeLabel',
                    label: 'Valve Full Closure Travel Time (sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '5px 0 0 4px',
                },
                {
                    key: 'F965AftValveFullClosureTravelTime',
                    value: formData.F965AftValveFullClosureTravelTime,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                },
                {
                    key: 'F966AftValveFullOpeningTravelTimeLabel',
                    label: 'Valve Full Opening Travel Time (sec)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F966AftValveFullOpeningTravelTime',
                    value: formData.F966AftValveFullOpeningTravelTime,
                    xs: 2,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderRight: '0',
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F967AftValveClosureMovementLabel',
                    label: 'Valve Closure Movement',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '5px 0 0 4px',
                },
                {
                    key: 'F967AftValveClosureMovement',
                    value: formData.F967AftValveClosureMovement,
                    isDropdown: true,
                    placeholder: 'Select Movement',
                    options: [{ name: 'Select Movement', value: '' },
                    { name: 'Smooth', value: 'Smooth' },
                    { name: 'Abnormal', value: 'Abnormal' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                },
                {
                    key: 'F968AftConfirmLimitSwitchesWorkLabel',
                    label: 'Confirm Limit Switches Work Correctly',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F968AftConfirmLimitSwitchesWork',
                    value: formData.F968AftConfirmLimitSwitchesWork,
                    isDropdown: true,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderRight: '0',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 6,
                },
                {
                    key: 'F102AftResultLabel1',
                    label: 'As-Found Result',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultFormulaS71(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    borderRight: '0',
                    marginBottom: '4px',
                    borderBottom: '0'
                }
            ]
        }
    ]
});
