import styled from 'styled-components';
import { bgColorWithData, borderValue } from './FormInput/Inputs.styled';

export const StyledInput = styled.input`
    font-size: 10pt;
    font-family: "Open Sans", sans-serif;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    padding: 5px;
    min-width: 90%;
    pointer-events:${props => props.isLocked ? 'none' : null};
    background-color: ${props => {
        if (props.isLocked) {
            return 'rgb(232, 232, 232)';
        }
        if (props.isRequired && (props.value || props.value === 0)) {
            return bgColorWithData;
        }
        if (props.isRequired) {
            return 'rgb(255, 255, 0)';
        }
        return 'rgb(255, 255, 255)';
    }};
    border-style: solid;
    border-width: ${props => {
        if (props.readOnly && props.formName) {
            return '0;';
        }
        return '2px;';
    }
    }
    border-color: rgb(166, 166, 166);
    border-radius:0;
    -webkit-border-radius:0;
    -webkit-appearance:none;
    height: ${props => props.height ? props.height : '1rem'};
    resize:none;
    outline:0;
    overflow-y:auto;
    margin:0;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        ${props => (props.type === 'number' ? '-webkit-appearance: none; margin: 0;' : '')}
    }

    ${props => (props.type === 'number' ? '/* Firefox */ -moz-appearance: textfield;' : '')}

    &:focus {
        ${props => !props.readOnly || !props.formName
        ? 'border: 4px solid rgb(166, 166, 166) !important;margin:-2px;' : borderValue
    }
    
    &:hover {
        ${props => !props.readOnly || !props.formName
        ? 'border : 2px solid rgb(51, 91, 3);' : borderValue
    }
    
    &:active {
        ${props => !props.readOnly || !props.formName
        ? 'border : 4px solid rgb(166, 166, 166)!important;margin: -2px;' : borderValue
    }
`;
export const InputWrapper = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 5px;
    .label {
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '5px'};
        font-weight: 600;
        font-size: 10pt;
        font-family: "Open Sans", sans-serif;
        margin-top: ${props => props.marginTop};
        color: ${props => props.fontColor ? props.fontColor : 'black'};
    }
`;

export const ViewWrapper = styled.div`
    font-family: "Segoe UI", "Open Sans", sans-serif;
    font-size: 10pt;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    padding: 5px;
`;

export const StyledTextArea = styled.textarea`
font-size:  ${props => props.fontSize ? props.fontSize : '10pt'};
color: rgb(51, 51, 51);
font-weight: normal;
font-style: normal;
font-family: "Open Sans", sans-serif;
text-align: left;
line-height: 1.2;
padding: 5px;
min-width: 90%;
width: ${props => props.width ? '90%' : '97%'};
pointer-events:${props => props.isLocked ? 'none' : null};
background-color: ${props => {
    if (props.isLocked) {
        return 'rgb(232, 232, 232)';
    }
    if (props.isRequired && (props.value || props.value === 0)) {
        return bgColorWithData;
    }
    if (props.isRequired) {
        return 'rgb(255, 255, 0)';
    }
    return 'rgb(255, 255, 255)';
}};
border-style: solid;
border-width: ${props => props.readOnly && props.formName ? '0;' : '2px;'}
border-color: rgb(166, 166, 166);
border-radius:0;
-webkit-border-radius:0;
-webkit-appearance:none;

resize:none;
outline:0;
overflow-y:auto;
margin:0;

    &:focus {
        ${props => !props.readOnly || !props.formName
        ? 'border: 4px solid rgb(166, 166, 166) !important; margin: -2px; ' : borderValue
    }
}
    
    &:hover {
        ${props => !props.readOnly || !props.formName
        ? 'border: 2px solid rgb(51, 91, 3);' : borderValue
    }
}
    
    &:active {
        ${props => !props.readOnly || !props.formName
        ? 'border: 4px solid rgb(166, 166, 166)!important;margin: -2px;' : borderValue
    }
}
`;
