import { FieldHasValue } from 'helpers/Validation';
import { Placeholder, Result } from '../../Sections.constants';

const FlameproofLabels = [
    'Glands, adapters, tees, plugs etc. are Ex ‘d’ certified',
    'No tape over flame paths for IIC equipment unless permitted by the certification documents. No paint over flame paths for all gas groups',
    'No obstructions near flameproof flanged joints (IIA 10mm, IIB 30mm, IIC  40mm) – in accordance with IEC 60079-14',
    'Threaded covers on enclosures are of the correct type, are tight and secured',
    'Stopping boxes and cable boxes are correctly filled ',
    'Flange gap dimensions are within the permitted maximum ',
    'Electrical Insulation is clean and dry'
];

const IncreasedSafetyLabels = [
    'Cable glands and plugs meet higher of IP54 or IP of enclosure',
    'All unused cores are terminated and unused terminals are tightened down',
    'All equipment within the enclosure is suitably certified',
    'Conductors are not bunched and no longer than diagonal length of enclosure',
    'Additional entries, if made, are allowed by the manufacturer’s certificate'
];

const IntrinsicallySafeLabels = [
    'Verify IS circuit carries correct circuit identification',
    'Ensure IS circuit is label or has a unique identifier',
    'Ensure there is no damage or evidence of unauthorised modification',
    'Ensure IS device (Barriers, galvanic isolators and relays) are approved for instrinsically safe application',
    'Verify earth continuity is satisfactory'
];

const EncapsulationLabels = [
    'Encapsulation material is free from damage, shrinkage,  decomposition and loss of adhesion '
];

const NonSparkingLabels = [
    'Enclosed-break and hermetically sealed devices are undamaged',
    'Encapsulated components are undamaged',
    'Restricted breathing encloseure is satisfactory (nR Type)',
    'Test port, if fitted, is functional (nR Type)',
    'Breathing and draining devices are satisfactory'
];

const PurgePressurizedLabels = [
    'Ducts, tubing and enclosures are in good condition and does not have any leaks.',
    'Spark and particle barriers on ducts or tubing are in good condition',
    'Pressure, flow indicators, alarms, interlocks function correctly ',
    'Ensure purge monitoring circuit is functioning correctly (check timing and isolation function) and pre‐energized purge period is adequate',
    'Confirm that the pressure and/or flow is adequate',
    'Ensure pressurized enclosure is holding a positive pressure with non-hazardous gas'
];

export const IsFlameproofTrf = formData => formData.F957AdditionalInspectionNeeded === 'D - Ex d (Flameproof)';
export const IsIncreasedSafetyTrf = formData => formData.F957AdditionalInspectionNeeded === 'E - Ex e (Increased Safety)';
export const IsIntrinsicallySafeTrf = formData => formData.F957AdditionalInspectionNeeded === 'I - Ex I (Intrinsically Safe)';
export const IsEncapsulationTrf = formData => formData.F957AdditionalInspectionNeeded === 'M - Ex M (Encapsulation)';
export const IsNonSparkingTrf = formData => formData.F957AdditionalInspectionNeeded === 'N - Ex n (Non-Sparking)';
export const IsPurgePressurizedTrf = formData => formData.F957AdditionalInspectionNeeded === 'P - Ex p (Purge Pressurized)';

const paddingLabel = '5px 0 10px 8px';

const generateRows = (labels, formData, type, prefix, header) => {
    const rows = [];
    const labelPrefix = (type === 'IntrinsicallySafe' || type === 'Encapsulation') ? prefix : prefix.toLowerCase();
    rows.push({
        key: `${type}Header`,
        fields: [{
            key: type,
            label: `${prefix} - Ex ${labelPrefix} (${header})`,
            isLabel: true,
            fontWeight: '700',
            xs: 12,
            textAlign: 'left',
            padding: paddingLabel,
            fontSize: '9pt',
        }]
    });

    labels.forEach((label, i) => {
        const labelKey = `${prefix}${i + 1}`;
        rows.push({
            key: `${type}${i + 1}`,
            fields: [
                {
                    key: `AdditionalLabel${i}`,
                    label: labelKey,
                    xs: 1,
                    labelWidth: '100%',
                    textAlign: 'center',
                    isLabel: true,
                    fontWeight: '700',
                    padding: '0',
                    fontSize: '9pt',
                },
                {
                    key: `Label${i}`,
                    label,
                    xs: 5,
                    textAlign: 'left',
                    fontWeight: '600',
                    fontSize: '9pt',
                    labelWidth: '100%',
                    isLabel: true,
                    padding: '0 2px 0 0'
                },
                {
                    key: `${type}${labelKey}`,
                    value: formData[`${type}${labelKey}`],
                    isRequired: true,
                    xs: 1,
                    labelWidth: '0%',
                    textAlign: 'center',
                    width: '100%',
                    height: '2.3rem',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: [
                        { name: Placeholder.SELECT, value: '' },
                        { name: Result.RESULT_PASS, value: Result.RESULT_PASS },
                        { name: Result.RESULT_FAIL, value: Result.RESULT_FAIL },
                        { name: Result.RESULT_NA, value: Result.RESULT_NA }
                    ],
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    marginBottom: i === (labels.length - 1) ? '4px' : undefined,
                    paddingPlaceholder: '7px',
                    arrowMarginTop: '7px'
                },
                {
                    key: `${type}Comments${labelKey}`,
                    value: formData[`${type}Comments${labelKey}`],
                    xs: 5,
                    labelWidth: '0%',
                    inputWidth: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                    inputMarginRight: '4px',
                    maxLength: '60',
                    marginBottom: i === (labels.length - 1) ? '4px' : '',
                    isMultiLine: true,
                    marginRight: '0',
                }
            ]
        });
    });

    return rows;
};

export const additionalInspectionChecklist = (formName, formData) => {
    const rows = [];

    if (IsFlameproofTrf(formData)) {
        rows.push(...generateRows(FlameproofLabels, formData, 'Flameproof', 'D', 'Flameproof'));
    }

    if (IsIncreasedSafetyTrf(formData)) {
        rows.push(...generateRows(IncreasedSafetyLabels, formData, 'IncreasedSafety', 'E', 'Increased Safety'));
    }

    if (IsIntrinsicallySafeTrf(formData)) {
        rows.push(...generateRows(IntrinsicallySafeLabels, formData, 'IntrinsicallySafe', 'I', 'Intrinsically Safe'));
    }

    if (IsEncapsulationTrf(formData)) {
        rows.push(...generateRows(EncapsulationLabels, formData, 'Encapsulation', 'M', 'Encapsulation'));
    }

    if (IsNonSparkingTrf(formData)) {
        rows.push(...generateRows(NonSparkingLabels, formData, 'NonSparking', 'N', 'Non-Sparking'));
    }

    if (IsPurgePressurizedTrf(formData)) {
        rows.push(...generateRows(PurgePressurizedLabels, formData, 'PurgePressurized', 'P', 'Purge Pressurized'));
    }

    return { rows };
};

export const selectAdditionalInspectionChecklistData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M4-2' && FieldHasValue(formData.F957AdditionalInspectionNeeded)) {
        filterViewData = additionalInspectionChecklist(formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
