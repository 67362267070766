import React from 'react';
import PropTypes from 'prop-types';
import { FormInput, FormInputMultiline } from 'elements/Inputs';
import { FormDropdown } from 'elements/Dropdown';
import { SearchableDropdown } from 'elements/Inputs/FormInput/SearchableDropdown';
import Grid from '@material-ui/core/Grid';
import UserInformationSectionAdd from './UserInformationSectionAdd';
import UserInformationSectionEdit from './UserInformationSectionEdit';
import { FlocExist, StyleDropdown } from '../DPS/DPS.styled';
import { StyledLabel } from 'elements';
import { classOptions, gasGroupOptions, equipmentProtectionLevelOptions, temperaturClassOptions, zoneOptions, divisionOptions, Placeholder } from 'journeys/portal/Forms/Sections.constants';

export const DpsForm = ({ formId, formValues, formHandler, isEditDPS, trfMasterList, readOnly, sapFlocExist, dpsLockedFields, dpsMaxLengthMapper }) => {
    const paddingVal = '15px 5px 0';
    const note = '*Greyed out fields require Regional Engineer to update the data in the Source Data application (ie. ALIM)';
    return (
        <form id={formId}>
            {isEditDPS ? <UserInformationSectionEdit /> : <UserInformationSectionAdd />}
            <StyleDropdown>
                <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4} style={{ position: 'relative' }}>
                            <FlocExist>{sapFlocExist ? '*(Floc Already Exist)' : ''}</FlocExist>
                            <div>
                                <FormInput
                                    id="SapFloc"
                                    name="SapFloc"
                                    label="Functional Location"
                                    isRequired
                                    value={formValues.SapFloc ?? ''}
                                    handleChange={formHandler}
                                    readOnly={readOnly || (isEditDPS && dpsLockedFields?.SAP_FLOC)}
                                    isLocked={(isEditDPS && dpsLockedFields?.SAP_FLOC)}
                                    maxLength={dpsMaxLengthMapper?.SAP_FLOC}
                                    marginBottom="1px"
                                    height="0.5rem"
                                    fontColor="#00B050"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="LocationDescription"
                                name="LocationDescription"
                                label="Functional Location Description"
                                value={formValues.LocationDescription ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Location_Description}
                                isLocked={dpsLockedFields?.Location_Description}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Location_Description}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {isEditDPS
                                ? (
                                    <FormDropdown
                                        id="Status"
                                        name="Status"
                                        label="Status"
                                        width="86%"
                                        marginBottom="1px"
                                        isRequired
                                        handleChange={formHandler}
                                        value={formValues.Status ?? ''}
                                        options={[{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }]}
                                        readOnly={readOnly || dpsLockedFields?.Status}
                                        isLocked={dpsLockedFields?.Status}
                                        noDefaultOption
                                        placeholder=""
                                        height="1.5rem"
                                        padding="2px"
                                        arrowPadding="4px"
                                        fontColor="#00B050"
                                    />
                                )
                                : (
                                    <FormInput
                                        id="Status"
                                        label="Status"
                                        name="Status"
                                        value={formValues.Status}
                                        handleChange={formHandler}
                                        marginBottom="1px"
                                        height="0.5rem"
                                        readOnly="true"
                                        isRequired
                                        fontColor="#00B050"
                                        maxLength={dpsMaxLengthMapper?.Status}
                                    />
                                )}
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <SearchableDropdown
                                id="TrfId"
                                name="TrfId"
                                label="TRF_ID"
                                selectMarginLeft="16px"
                                handleChange={formHandler}
                                width="86%"
                                isRequired
                                value={formValues.TrfId}
                                options={[...trfMasterList
                                    .filter(x => x.ApplicableRegions && x.TrfId !== 'NA' && x.WorkType === 'Preventative' && x.DpsListStatus === 'Active')
                                    .map(x => ({ name: x.TrfId, value: x.TrfId }))]}
                                readOnlyDps={readOnly || dpsLockedFields?.TRF_ID}
                                isLocked={dpsLockedFields?.TRF_ID}
                                placeholder="Search TRF_ID"
                                height="1.5rem"
                                fontColor="#00B050"
                                marginTop="2px"
                                marginLeft="16px"
                                alignItems="flex-start"
                                flexDirection="column"
                                labelFontSize="10pt"
                                fontWeight="600"
                                labelColor="rgb(0, 176, 80)"
                                fontFamily="Arial, Helvetica, sans-serif"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="TrfDesc"
                                name="TrfDesc"
                                label="TRF Description"
                                marginBottom="1px"
                                handleChange={formHandler}
                                width="86%"
                                value={formValues.TrfId}
                                options={trfMasterList.filter(x => x.ApplicableRegions
                                    && formValues.TrfId === x.TrfId
                                    && x.WorkType === 'Preventative').map(x => x.Status === 'Active'
                                        ? ({ name: x.TrfShortDescReplaceWhenActive, value: x.TrfId })
                                        : ({ name: x.TrfShortDesc, value: x.TrfId }))}
                                readOnly={readOnly || dpsLockedFields?.TRF_Desc}
                                isLocked={dpsLockedFields?.TRF_Desc}
                                placeholder=""
                                noDefaultOption
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                marginTop="2px"
                                maxLength={dpsMaxLengthMapper?.TRF_Desc}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="DeviceType"
                                name="DeviceType"
                                label="Device Type"
                                value={formValues.DeviceType ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Device_Type}
                                isLocked={dpsLockedFields?.Device_Type}
                                marginBottom="1px"
                                height="0.5rem"
                                marginTop="2px"
                                maxLength={dpsMaxLengthMapper?.Device_Type}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <SearchableDropdown
                                id="CorrectiveTrfId"
                                name="CorrectiveTrfId"
                                label="Corrective TRF_ID"
                                selectMarginLeft="16px"
                                handleChange={formHandler}
                                fontWeight="600"
                                fontFamily="Arial, Helvetica, sans-serif"
                                labelColor="black"
                                width="86%"
                                value={formValues.CorrectiveTrfId}
                                options={[...trfMasterList
                                    .sort((a, b) => a.Order < b.Order ? -1 : 1)
                                    .filter(x => x.ApplicableRegions && x.TrfId !== 'NA' && x.WorkType === 'Corrective' && x.DpsListStatus === 'Active')
                                    .map(x => ({ name: x.TrfId, value: x.TrfId }))]}
                                readOnlyDps={readOnly || dpsLockedFields?.Corrective_TRF_ID}
                                isLocked={dpsLockedFields?.Corrective_TRF_ID}
                                placeholder="Search Corrective TRF_ID"
                                height="1.5rem"
                                marginTop="2px"
                                marginLeft="16px"
                                alignItems="flex-start"
                                flexDirection="column"
                                labelFontSize="10pt"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="CorrectiveTrfDesc"
                                name="CorrectiveTrfDesc"
                                label="Corrective TRF Description"
                                handleChange={formHandler}
                                marginBottom="1px"
                                width="86%"
                                value={formValues.CorrectiveTrfId}
                                options={trfMasterList.filter(x => x.ApplicableRegions
                                    && formValues.CorrectiveTrfId === x.TrfId
                                    && x.WorkType === 'Corrective').map(x => ({ name: x.TrfShortDesc, value: x.TrfId }))}
                                readOnly={readOnly || dpsLockedFields?.Corrective_TRF_Desc}
                                isLocked={dpsLockedFields?.Corrective_TRF_Desc}
                                placeholder=""
                                noDefaultOption
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                marginTop="2px"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Service"
                                name="Service"
                                label="Service"
                                value={formValues.Service ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Service}
                                isLocked={dpsLockedFields?.Service}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Service}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <SearchableDropdown
                                id="InspectionIrfId"
                                name="InspectionIrfId"
                                label="Inspection IRF_ID"
                                selectMarginLeft="16px"
                                handleChange={formHandler}
                                width="86%"
                                value={formValues.InspectionIrfId}
                                options={[...trfMasterList
                                    .filter(x => x.ApplicableRegions && x.TrfId !== 'NA' && x.WorkType === 'Inspection' && x.DpsListStatus === 'Active')
                                    .map(x => ({ name: x.TrfId, value: x.TrfId }))]}
                                readOnlyDps={readOnly || dpsLockedFields?.Inspection_IRF_Id}
                                isLocked={dpsLockedFields?.Inspection_IRF_Id}
                                placeholder="Search Inspection IRF_ID"
                                height="1.5rem"
                                fontColor="#00B050"
                                marginTop="2px"
                                marginLeft="16px"
                                alignItems="flex-start"
                                flexDirection="column"
                                labelFontSize="10pt"
                                fontWeight="600"
                                labelColor="black"
                                fontFamily="Arial, Helvetica, sans-serif"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="InspectionIrfDescription"
                                name="InspectionIrfDescription"
                                label="Inspection IRF Description"
                                marginBottom="1px"
                                handleChange={formHandler}
                                width="86%"
                                value={formValues.InspectionIrfId}
                                options={trfMasterList.filter(x => x.ApplicableRegions
                                    && formValues.InspectionIrfId === x.TrfId
                                    && x.WorkType === 'Inspection').map(x => x.Status === 'Active'
                                        ? ({ name: x.TrfShortDescReplaceWhenActive, value: x.TrfId })
                                        : ({ name: x.TrfShortDesc, value: x.TrfId }))}
                                readOnly={readOnly || dpsLockedFields?.Inspection_IRF_Description}
                                isLocked={dpsLockedFields?.Inspection_IRF_Description}
                                placeholder=""
                                noDefaultOption
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                marginTop="2px"
                                maxLength={dpsMaxLengthMapper?.Inspection_IRF_Description}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="IcssSoftTag"
                                name="IcssSoftTag"
                                label="ICSS Soft Tag"
                                value={formValues.IcssSoftTag ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.ICSS_Soft_Tag}
                                isLocked={dpsLockedFields?.ICSS_Soft_Tag}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.ICSS_Soft_Tag}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInputMultiline
                                id="Manufacturer"
                                name="Manufacturer"
                                label="Manufacturer"
                                value={formValues.Manufacturer ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer}
                                isLocked={dpsLockedFields?.Manufacturer}
                                marginBottom="1px"
                                height="0.5rem"
                                marginTop="2px"
                                maxLength={dpsMaxLengthMapper?.Manufacturer}
                                width="90%"
                                rows={2}
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInputMultiline
                                id="Model"
                                name="Model"
                                label="Model"
                                value={formValues.Model ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model}
                                isLocked={dpsLockedFields?.Model}
                                marginBottom="1px"
                                height="0.5rem"
                                marginTop="2px"
                                maxLength={dpsMaxLengthMapper?.Model}
                                width="90%"
                                rows={2}
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="InstrumentCalibratedLrv"
                                name="InstrumentCalibratedLrv"
                                label="Instrument Calibrated LRV"
                                value={formValues.InstrumentCalibratedLrv ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Instrument_Calibrated_LRV}
                                isLocked={dpsLockedFields?.Instrument_Calibrated_LRV}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Instrument_Calibrated_LRV}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="HmiLrv"
                                name="HmiLrv"
                                label="HMI LRV"
                                value={formValues.HmiLrv ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.HMI_LRV}
                                isLocked={dpsLockedFields?.HMI_LRV}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.HMI_LRV}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="FailureModeLowCurrentMA"
                                name="FailureModeLowCurrentMA"
                                label="Failure Mode Low Current (mA)"
                                value={formValues.FailureModeLowCurrentMA ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Failure_Mode_Low_Current_mA}
                                isLocked={dpsLockedFields?.Failure_Mode_Low_Current_mA}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Failure_Mode_Low_Current_mA}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="InstrumentCalibratedUrv"
                                name="InstrumentCalibratedUrv"
                                label="Instrument Calibrated URV"
                                value={formValues.InstrumentCalibratedUrv ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Instrument_Calibrated_URV}
                                isLocked={dpsLockedFields?.Instrument_Calibrated_URV}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Instrument_Calibrated_URV}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="HmiUrv"
                                name="HmiUrv"
                                label="HMI URV"
                                value={formValues.HmiUrv ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.HMI_URV}
                                isLocked={dpsLockedFields?.HMI_URV}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.HMI_URV}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="FailureModeHighCurrentMA"
                                name="FailureModeHighCurrentMA"
                                label="Failure Mode High Current (mA)"
                                value={formValues.FailureModeHighCurrentMA ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Failure_Mode_High_Current_mA}
                                isLocked={dpsLockedFields?.Failure_Mode_High_Current_mA}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Failure_Mode_High_Current_mA}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="InstrumentEngineeringUnits"
                                name="InstrumentEngineeringUnits"
                                label="Instrument Engineering Unit"
                                value={formValues.InstrumentEngineeringUnits ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Instrument_Engineering_Units}
                                isLocked={dpsLockedFields?.Instrument_Engineering_Units}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                                maxLength={dpsMaxLengthMapper?.Instrument_Engineering_Units}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="HmiEngineeringUnits"
                                name="HmiEngineeringUnits"
                                label="HMI Engineering Unit"
                                value={formValues.HmiEngineeringUnits ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.HMI_Engineering_Units}
                                isLocked={dpsLockedFields?.HMI_Engineering_Units}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                                maxLength={dpsMaxLengthMapper?.HMI_Engineering_Units}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Span"
                                name="Span"
                                label="Span (F&G Detector)"
                                value={formValues.Span ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Span}
                                isLocked={dpsLockedFields?.Span}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Span}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="ToleranceInInstrumentEu"
                                name="ToleranceInInstrumentEu"
                                label="Tolerance In Instrument EU"
                                value={formValues.ToleranceInInstrumentEu ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Tolerance_In_Instrument_EU}
                                isLocked={dpsLockedFields?.Tolerance_In_Instrument_EU}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Tolerance_In_Instrument_EU}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="FailTolerance"
                                name="FailTolerance"
                                label="Fail Tolerance"
                                value={formValues.FailTolerance ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Fail_Tolerance}
                                isLocked={dpsLockedFields?.Fail_Tolerance}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Fail_Tolerance}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="ReCalibrationTolerance"
                                name="ReCalibrationTolerance"
                                label="Re-Calibration Tolerance"
                                value={formValues.ReCalibrationTolerance ?? ''}
                                isLocked={dpsLockedFields?.Re_Calibration_Tolerance}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Re_Calibration_Tolerance}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Re_Calibration_Tolerance}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="ToleranceInInstrumentEUComment"
                                name="ToleranceInInstrumentEUComment"
                                label="Tolerance In Instrument EU Comment"
                                value={formValues.ToleranceInInstrumentEUComment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Tolerance_In_Instrument_EU_Comment}
                                isLocked={dpsLockedFields?.Tolerance_In_Instrument_EU_Comment}
                                maxLength={dpsMaxLengthMapper?.Tolerance_In_Instrument_EU_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="FailToleranceEU"
                                name="FailToleranceEU"
                                label="Fail Tolerance EU"
                                value={formValues.FailToleranceEU ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Fail_Tolerance_EU}
                                isLocked={dpsLockedFields?.Fail_Tolerance_EU}
                                maxLength={dpsMaxLengthMapper?.Fail_Tolerance_EU}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="ReCalibrationToleranceEU"
                                name="ReCalibrationToleranceEU"
                                label="Re-Calibration Tolerance EU"
                                value={formValues.ReCalibrationToleranceEU ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Re_Calibration_Tolerance_EU}
                                isLocked={dpsLockedFields?.Re_Calibration_Tolerance_EU}
                                maxLength={dpsMaxLengthMapper?.Re_Calibration_Tolerance_EU}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="HighHighSetpoint"
                                name="HighHighSetpoint"
                                label="High High Setpoint"
                                value={formValues.HighHighSetpoint ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.High_High_Setpoint}
                                isLocked={dpsLockedFields?.High_High_Setpoint}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.High_High_Setpoint}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="HighHighSetpointComment"
                                name="HighHighSetpointComment"
                                label="High High Setpoint Comment"
                                value={formValues.HighHighSetpointComment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.High_High_Setpoint_Comment}
                                isLocked={dpsLockedFields?.High_High_Setpoint_Comment}
                                maxLength={dpsMaxLengthMapper?.High_High_Setpoint_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="ValveSize"
                                name="ValveSize"
                                label="Valve Size (inch)"
                                value={formValues.ValveSize ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Valve_Size}
                                isLocked={dpsLockedFields?.Valve_Size}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Valve_Size}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="LowLowSetpoint"
                                name="LowLowSetpoint"
                                label="Low Low Setpoint"
                                value={formValues.LowLowSetpoint ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Low_Low_Setpoint}
                                isLocked={dpsLockedFields?.Low_Low_Setpoint}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Low_Low_Setpoint}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="LowLowSetpointComment"
                                name="LowLowSetpointComment"
                                label="Low Low Setpoint Comment"
                                value={formValues.LowLowSetpointComment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Low_Low_Setpoint_Comment}
                                isLocked={dpsLockedFields?.Low_Low_Setpoint_Comment}
                                maxLength={dpsMaxLengthMapper?.Low_Low_Setpoint_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="DesignElementResponseTime"
                                name="DesignElementResponseTime"
                                label="Element Response Time (sec)"
                                value={formValues.DesignElementResponseTime ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Design_Element_Response_Time}
                                isLocked={dpsLockedFields?.Design_Element_Response_Time}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Design_Element_Response_Time}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="SetPointHigh"
                                name="SetPointHigh"
                                label="High Setpoint"
                                value={formValues.SetPointHigh ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Set_Point_High}
                                isLocked={dpsLockedFields?.Set_Point_High}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Set_Point_High}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="HighSetpointComment"
                                name="HighSetpointComment"
                                label="High Setpoint Comment"
                                value={formValues.HighSetpointComment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.High_Setpoint_Comment}
                                isLocked={dpsLockedFields?.High_Setpoint_Comment}
                                maxLength={dpsMaxLengthMapper?.High_Setpoint_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="TargetLeakageClass"
                                name="TargetLeakageClass"
                                label="Target Leakage Class"
                                value={formValues.TargetLeakageClass ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Target_Leakage_Class}
                                isLocked={dpsLockedFields?.Target_Leakage_Class}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Target_Leakage_Class}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="LowSetPoint"
                                name="LowSetPoint"
                                label="Low Setpoint"
                                value={formValues.LowSetPoint ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Low_Set_Point}
                                isLocked={dpsLockedFields?.Low_Set_Point}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Low_Set_Point}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="LowSetpointComment"
                                name="LowSetpointComment"
                                label="Low Setpoint Comment"
                                value={formValues.LowSetpointComment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Low_Setpoint_Comment}
                                isLocked={dpsLockedFields?.Low_Setpoint_Comment}
                                maxLength={dpsMaxLengthMapper?.Low_Setpoint_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="TargetLeakageRate"
                                name="TargetLeakageRate"
                                label="Target Leakage Rate"
                                value={formValues.TargetLeakageRate ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Target_Leakage_Rate}
                                isLocked={dpsLockedFields?.Target_Leakage_Rate}
                                marginBottom="1px"
                                height="0.5rem"
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Target_Leakage_Rate}
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="SwitchSetpoint"
                                name="SwitchSetpoint"
                                label="Switch Setpoint"
                                value={formValues.SwitchSetpoint ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Switch_Setpoint}
                                isLocked={dpsLockedFields?.Switch_Setpoint}
                                type="number"
                                maxLength={dpsMaxLengthMapper?.Switch_Setpoint}
                                marginBottom="1px"
                                height="0.5rem"
                                fontColor="#00B050"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="SwitchSetpointComment"
                                name="SwitchSetpointComment"
                                label="Switch Setpoint Comment"
                                value={formValues.SwitchSetpointComment ?? ''}
                                isLocked={dpsLockedFields?.Switch_Setpoint_Comment}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Switch_Setpoint_Comment}
                                maxLength={dpsMaxLengthMapper?.Switch_Setpoint_Comment}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="TargetLeakageRateEu"
                                name="TargetLeakageRateEu"
                                label="Target Leakage Rate EU"
                                value={formValues.TargetLeakageRateEu ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Target_Leakage_Rate_EU}
                                isLocked={dpsLockedFields?.Target_Leakage_Rate_EU}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Target_Leakage_Rate_EU}
                                fontColor="#00B050"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="Class"
                                name="Class"
                                label="Class"
                                value={formValues.Class ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Class}
                                isLocked={dpsLockedFields?.Class}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Class}
                                fontColor="#00B050"
                                placeholder={Placeholder.SELECT}
                                options={classOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"

                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="GasGroup"
                                name="GasGroup"
                                label="Gas Group"
                                value={formValues.GasGroup ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Gas_Group}
                                isLocked={dpsLockedFields?.Gas_Group}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Gas_Group}
                                fontColor="#00B050"
                                placeholder={Placeholder.SELECT}
                                options={gasGroupOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="Zone"
                                name="Zone"
                                label="Zone"
                                value={formValues.Zone ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Zone}
                                isLocked={dpsLockedFields?.Zone}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Zone}
                                placeholder={Placeholder.SELECT}
                                options={zoneOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="Division"
                                name="Division"
                                label="Division"
                                value={formValues.Division ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Division}
                                isLocked={dpsLockedFields?.Division}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Division}
                                fontColor="#00B050"
                                placeholder={Placeholder.SELECT}
                                options={divisionOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                marginTop="2px"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="TemperatureClass"
                                name="TemperatureClass"
                                label="Temperature Class"
                                value={formValues.TemperatureClass ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Temperature_Class}
                                isLocked={dpsLockedFields?.Temperature_Class}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Temperature_Class}
                                fontColor="#00B050"
                                placeholder={Placeholder.SELECT}
                                options={temperaturClassOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                marginTop="2px"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormDropdown
                                id="EquipmentProtectionLevel"
                                name="EquipmentProtectionLevel"
                                label="Equipment Protection Level (EPL)"
                                value={formValues.EquipmentProtectionLevel ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Equipment_Protection_Level}
                                isLocked={dpsLockedFields?.Equipment_Protection_Level}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Equipment_Protection_Level}
                                placeholder={Placeholder.SELECT}
                                options={equipmentProtectionLevelOptions}
                                width="86%"
                                height="1.5rem"
                                padding="2px"
                                arrowPadding="4px"
                                labelWidth="15rem"
                                marginTop="2px"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="DeckHullBldg"
                                name="DeckHullBldg"
                                label="Deck / Hull / Building"
                                value={formValues.DeckHullBldg ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Deck_Hull_Bldg}
                                isLocked={dpsLockedFields?.Deck_Hull_Bldg}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Deck_Hull_Bldg}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Quad"
                                name="Quad"
                                label="Quad"
                                value={formValues.Quad ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Quad}
                                isLocked={dpsLockedFields?.Quad}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Quad}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Level"
                                name="Level"
                                label="Level"
                                value={formValues.Level ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Level}
                                isLocked={dpsLockedFields?.Level}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Level}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Room"
                                name="Room"
                                label="Room"
                                value={formValues.Room ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Room}
                                isLocked={dpsLockedFields?.Room}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Room}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Location2"
                                name="Location2"
                                label="Location"
                                value={formValues.Location2 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Location_2}
                                isLocked={dpsLockedFields?.Location_2}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Location_2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Doc1Number"
                                name="Doc1Number"
                                label="Document 1 Number"
                                value={formValues.Doc1Number ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Doc1_Number}
                                isLocked={dpsLockedFields?.Doc1_Number}
                                maxLength={dpsMaxLengthMapper?.Doc1_Number}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Doc2Number"
                                name="Doc2Number"
                                label="Document 2 Number"
                                value={formValues.Doc2Number ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Doc2_Number}
                                isLocked={dpsLockedFields?.Doc2_Number}
                                maxLength={dpsMaxLengthMapper?.Doc2_Number}
                                marginBottom="1px"
                                height="0.5rem"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInputMultiline
                                id="Doc1Url"
                                name="Doc1Url"
                                label="Document 1 URL"
                                value={formValues.Doc1Url ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Doc1_URL}
                                isLocked={dpsLockedFields?.Doc1_URL}
                                maxLength={dpsMaxLengthMapper?.Doc1_URL}
                                marginBottom="1px"
                                width="90%"
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInputMultiline
                                id="Doc2Url"
                                name="Doc2Url"
                                label="Document 2 URL"
                                value={formValues.Doc2Url ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Doc2_URL}
                                isLocked={dpsLockedFields?.Doc2_URL}
                                maxLength={dpsMaxLengthMapper?.Doc2_URL}
                                marginBottom="1px"
                                width="90%"
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer2"
                                name="Manufacturer2"
                                label="Manufacturer - Valve"
                                value={formValues.Manufacturer2 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_2}
                                isLocked={dpsLockedFields?.Manufacturer_2}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_2}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer3"
                                name="Manufacturer3"
                                label="Manufacturer - Actuator"
                                value={formValues.Manufacturer3 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_3}
                                isLocked={dpsLockedFields?.Manufacturer_3}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer4"
                                name="Manufacturer4"
                                label="Manufacturer - Solenoid"
                                value={formValues.Manufacturer4 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_4}
                                isLocked={dpsLockedFields?.Manufacturer_4}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer5"
                                name="Manufacturer5"
                                label="Manufacturer - I/P Positioner"
                                value={formValues.Manufacturer5 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_5}
                                isLocked={dpsLockedFields?.Manufacturer_5}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_5}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer6"
                                name="Manufacturer6"
                                label="Manufacturer - Position Transmitter"
                                value={formValues.Manufacturer6 ?? ''}
                                isLocked={dpsLockedFields?.Manufacturer_6}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_6}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_6}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Manufacturer7"
                                name="Manufacturer7"
                                label="Manufacturer - Limit Switch"
                                value={formValues.Manufacturer7 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Manufacturer_7}
                                isLocked={dpsLockedFields?.Manufacturer_7}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Manufacturer_7}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: paddingVal }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model2"
                                name="Model2"
                                label="Model - Valve"
                                value={formValues.Model2 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_2}
                                isLocked={dpsLockedFields?.Model_2}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_2}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model3"
                                name="Model3"
                                label="Model - Actuator"
                                value={formValues.Model3 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_3}
                                isLocked={dpsLockedFields?.Model_3}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model4"
                                name="Model4"
                                label="Model - Solenoid"
                                value={formValues.Model4 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_4}
                                isLocked={dpsLockedFields?.Model_4}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '0 5px' }}>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model5"
                                name="Model5"
                                label="Model - I/P Positioner"
                                value={formValues.Model5 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_5}
                                isLocked={dpsLockedFields?.Model_5}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_5}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model6"
                                name="Model6"
                                label="Model - Position Transmitter"
                                value={formValues.Model6 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_6}
                                isLocked={dpsLockedFields?.Model_6}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_6}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                id="Model7"
                                name="Model7"
                                label="Model - Limit Switch"
                                value={formValues.Model7 ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Model_7}
                                isLocked={dpsLockedFields?.Model_7}
                                marginBottom="1px"
                                height="0.5rem"
                                maxLength={dpsMaxLengthMapper?.Model_7}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ padding: '5px' }}>
                        <Grid item xs={12}>
                            <FormInputMultiline
                                id="Comment"
                                rows={3}
                                name="Comment"
                                label="Comment"
                                value={formValues.Comment ?? ''}
                                handleChange={formHandler}
                                readOnly={readOnly || dpsLockedFields?.Comment}
                                isLocked={dpsLockedFields?.Comment}
                                marginBottom="1px"
                                maxLength={dpsMaxLengthMapper?.Comment}
                            />
                        </Grid>
                    </Grid>
                    {Object.values(dpsLockedFields).filter(x => x).length > 3 && (
                        <Grid container item style={{ marginLeft: '10px', padding: '0' }}>
                            <Grid item xs={12}>
                                <StyledLabel
                                    label="test"
                                    padding="0 10px 10px 10px"
                                    fontWeight={500}
                                    color="rgb(234, 124, 52)"
                                    fontStyle="italic"
                                >
                                    {note}
                                </StyledLabel>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </StyleDropdown>
        </form>
    );
};
DpsForm.propTypes = {
    dpsLockedFields: PropTypes.array,
    dpsMaxLengthMapper: PropTypes.object,
    formHandler: PropTypes.func,
    formId: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    isEditDPS: PropTypes.bool,
    readOnly: PropTypes.bool,
    sapFlocExist: PropTypes.bool,
    trfMasterList: PropTypes.array.isRequired
};
