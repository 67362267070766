import { ZeroFlowF812DeviationFormula, ZeroFlowF477ZeroFlowCheckPassFailResultFormula } from './P6022.formulas';
import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

export const paddingValTPFR = '5px 5px 5px 0';
export const ZeroFlowCheckP6022 = (formData, formName) => {
    const signalToTriggerIsErrorCondition = signalToTriggerIsErrorCheck(formName, formData) || (formData.F473ZeroFlowReading === 'DISCARD');
    return ({
        rows: [{
            key: 1,
            fields: [{
                key: 'F473ZeroFlowReading',
                label: 'Zero Flow Check',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F473ZeroFlowReading : '',
                xs: 4,
                isDropdown: !signalToTriggerIsErrorCheck(formName, formData),
                isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                placeholder: 'Select Zero Flow Check',
                options: [
                    { name: 'Select Zero Flow Check', value: '' },
                    { name: 'PERFORM', value: 'PERFORM' },
                    { name: 'DISCARD', value: 'DISCARD' }],
                inputTextAlign: 'center',
                labelWidth: '50%',
                width: '50%',
                height: '1.5rem',
                borderTop: '0',
                marginLeft: '6px',
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                marginTop: '4px',
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: 'Remark: There must be no flow through the transmitter. Verify and record corresponding values based on process zero flow conditions.',
                fontWeight: '600',
                isLabel: true,
                labelWidth: '100%',
                width: '0',
                fontSize: '8.5pt',
                color: '#ea7c34',
                padding: '5px 25px 0px 7px'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'nodata',
                xs: 2,
                isLabel: true
            },
            {
                key: 'TXReading',
                label: 'TX Reading (EU)',
                isLabel: true,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                padding: paddingValTPFR,
                xs: 2,
                fontSize: '9pt'
            },
            {
                key: 'HMIReadingLabel',
                label: 'HMI Reading (HMI EU)',
                isLabel: true,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                padding: paddingValTPFR,
                xs: 2,
                fontSize: '9pt'
            },
            {
                key: 'MAreading',
                label: 'mA Reading',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingValTPFR,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (EU)',
                isLabel: true,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                padding: paddingValTPFR,
                xs: 2,
                fontSize: '9pt'
            },
            {
                key: 'ZeroFlowCheckResult',
                label: 'Zero Flow Check Result',
                labelWidth: '100%',
                isLabel: true,
                fontWeight: '700',
                textAlign: 'center',
                padding: paddingValTPFR,
                xs: 2,
                fontSize: '9pt'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F810ZeroFlowLabel',
                label: 'Zero flow',
                isLabel: true,
                xs: 2,
                fontWeight: '600',
                labelWidth: '98%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                padding: '0'
            },
            {
                key: 'F810ZeroFlow',
                value: !(signalToTriggerIsErrorCondition) ? formData.F810ZeroFlow : '',
                isRequired: !(signalToTriggerIsErrorCondition),
                isDisabled: signalToTriggerIsErrorCondition,
                readOnly: signalToTriggerIsErrorCondition,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F815Hmireading',
                value: !(signalToTriggerIsErrorCondition) ? formData.F815Hmireading : '',
                xs: 2,
                isRequired: !(signalToTriggerIsErrorCondition),
                isDisabled: signalToTriggerIsErrorCondition,
                readOnly: signalToTriggerIsErrorCondition,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F811MAreading',
                value: !(signalToTriggerIsErrorCondition) ? formData.F811MAreading : '',
                isRequired: !(signalToTriggerIsErrorCondition),
                isDisabled: signalToTriggerIsErrorCondition,
                readOnly: signalToTriggerIsErrorCondition,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F812Deviation',
                value: !(signalToTriggerIsErrorCondition) ? ZeroFlowF812DeviationFormula(formData) : '',
                xs: 2,
                isDisabled: signalToTriggerIsErrorCondition,
                readOnly: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                borderLeft: '0',
                marginBottom: '4px'
            },
            {
                key: 'F477ZeroFlowCheckPassFailResult',
                value: !(signalToTriggerIsErrorCondition) ? ZeroFlowF477ZeroFlowCheckPassFailResultFormula(formData) : '',
                xs: 2,
                isDisabled: signalToTriggerIsErrorCondition,
                isCalculatedNew: true,
                readOnly: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                marginBottom: '4px',
                inputMarginRight: '4px'
            }]
        }]
    });
};
