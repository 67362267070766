export const dpsSourceMappingData = [
    {
        field: 'DpssourceMappingId',
        headerName: 'DPS Source Mapping ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Region',
        headerName: 'Region',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'BpRegionAlias',
        headerName: 'Region Alias',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Dpsfield',
        headerName: 'DPS Field',
        width: '220',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Dpssource',
        headerName: 'DPS Source',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'IsCalculated',
        headerName: 'IsCalculated',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CalculationDetails',
        headerName: 'Calculation Details',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'IsReadOnly',
        headerName: 'IsReadOnly',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const dpsSourceMappingInitialState = {
    DpssourceMappingId: null,
    RegionId: null,
    Region: null,
    DpsfieldId: null,
    DpssourceId: null,
    BpRegionAlias: null,
    IsCalculated: 'No',
    CalculationDetails: null,
    IsReadOnly: 'No',
    Status: 'Inactive',
    CreatedBy: null,
    CreatedDate: null,
    ModifiedBy: null,
    LastModifiedDate: null,
    Comment: null,
};

export const searchDataInitialState = {
    DpssourceMappingId: '',
    RegionId: '',
    Region: '',
    DpsfieldId: '',
    DpssourceId: '',
    BpRegionAlias: '',
    IsCalculated: '',
    CalculationDetails: '',
    IsReadOnly: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
};

export const dpsSourceMappingApiEndpoint = {
    read: 'ADMIN_GetDPSsourceMapping'
};
