import { FieldHasValue } from 'helpers/Validation';

export const FoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F779AftMovedTargetPositionActivateSwitch)
        && FieldHasValue(formData.F780AftMovedTargetPositionDeactivateSwitch)
        && FieldHasValue(formData.F781AftConfirmSwitchActivatesCorrectly)
        && FieldHasValue(formData.F782AftConfirmSwitchDeactivatesCorrectly)) {
        return (formData.F779AftMovedTargetPositionActivateSwitch === 'YES'
            && formData.F780AftMovedTargetPositionDeactivateSwitch === 'YES'
            && formData.F781AftConfirmSwitchActivatesCorrectly === 'YES'
            && formData.F782AftConfirmSwitchDeactivatesCorrectly === 'YES')
            ? 'PASS' : 'FAIL';
    }
    return '';
};
