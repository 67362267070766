import { TestPointRange, conditionalValueSelector } from 'helpers/Validation';
import { AsFoundTestDeviation1Formula } from '../../../AsFoundTestTableSection/Data/P720/P720.formulas';
import { AsLeftResultFormula, AltSystemReadingFormulaValueCheck } from '../P721/P721.formulas';
import { AsLeftTestDeviationFormula, AsLeftResultMandatoryCheck, AltOutputOffsetFormula, setAltTestPoint } from './P720.formulas';
import { getAltDeleteIconStatus, disableAsLeft } from '../../AsLeftTestTableSection.helpers';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsLeftTestTableSectionDataP720 = (formData, altTestPoint) => {
    const rows = [];
    const altDisabled = disableAsLeft(formData.F102AftResult);
    const hideDeleteLabel = altDisabled || !getAltDeleteIconStatus();
    rows.push({
        key: 1,
        fields: [{
            key: 'AsLeftTest',
            label: 'As-Left Test',
            isLabel: true,
            xs: 3,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F721NumberOfTestPoints',
            label: 'Number of Test Points',
            value: setAltTestPoint(formData, altTestPoint),
            isRequired: (!altDisabled),
            isDisabled: altDisabled,
            readOnly: altDisabled || getAltDeleteIconStatus(),
            type: 'number',
            isBlur: true,
            maxLength: '10',
            xs: 3,
            labelWidth: '80.2%',
            width: '64%',
            height: '1.26rem',
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            hasTestPointError: !TestPointRange(formData?.F721NumberOfTestPoints, 10)
        },
        {
            key: 'F722TransducerOutputSignal',
            label: 'Sensor Output Signal',
            value: conditionalValueSelector(altDisabled, '', formData.F722TransducerOutputSignal),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            isDropdown: !altDisabled,
            xs: 3,
            isRequired: !altDisabled,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'Voltage', value: 'Voltage' },
                { name: 'Current', value: 'Current' }],
            inputTextAlign: 'center',
            labelWidth: '52%',
            width: '49.1%',
            height: '1.5rem',
            borderTop: '0',
            marginTop: '4px',
            marginRight: '0.28rem',
            direction: 'rtl',
        },
        {
            key: 'F775AltOutputOffset',
            label: AltOutputOffsetFormula(formData),
            value: conditionalValueSelector(altDisabled, '', (formData.F775AltOutputOffset)),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            type: 'number',
            maxLength: '10',
            xs: 3,
            labelWidth: '67%',
            borderRight: '0',
            width: '50%',
            height: '1.26rem',
            marginRight: '0.2rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            inputMarginRight: '4px'
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'AltDelete',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'AltVibrationAmplitude',
                label: 'Vibration Amplitude',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'AltVibrationFrequency',
                label: 'Vibration Frequency',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'AltSensorOutput',
                label: 'Sensor Output',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'AltSystemReading',
                label: 'System 1 Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'AltHMIReading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'AltDeviation',
                label: 'Deviation',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'AltDeleteLabel',
                    value: conditionalValueSelector(hideDeleteLabel, '', 'Delete'),
                    textAlign: 'center',
                    fontColor: 'red',
                    isDeleteLabel: getAltDeleteIconStatus(),
                    width: '100%',
                    labelWidth: '0',
                    height: conditionalValueSelector(
                        altDisabled, '1.65rem', '1.28rem'),
                    xs: 1,
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    readOnly: true,
                    marginLeft: '4px',
                    isDisabled: altDisabled
                },
                {
                    key: 'F781AltVibrationAmplitude',
                    value: conditionalValueSelector(altDisabled, '', formData?.F24HmiEngineeringUnit),
                    isDisabled: altDisabled,
                    isCalculatedNew: !altDisabled,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                    xs: 2,
                    borderRight: '0',
                    borderBottom: '0',
                    readOnly: true
                },
                {
                    key: 'F782AltVibrationFrequency',
                    xs: 1,
                    value: conditionalValueSelector(altDisabled, '', formData.F782AltVibrationFrequency),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isDropdown: (!altDisabled),
                    isRequired: (!altDisabled),
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'Hz', value: 'Hz' },
                        { name: 'CPM', value: 'CPM' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    borderBottom: '0',
                    height: '1.65rem'
                },
                {
                    key: 'F783AltSensorOutput1',
                    value: conditionalValueSelector(altDisabled, '', AsLeftTestDeviationFormula(formData)),
                    isDisabled: altDisabled,
                    isCalculatedNew: (!altDisabled),
                    width: '100%',
                    labelWidth: '0',
                    borderBottom: '0',
                    textAlign: 'center',
                    height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                    xs: 1,
                    borderRight: '0',
                    borderLeft: '0',
                    readOnly: true,
                },
                {
                    key: 'F784AltSensorOutput2',
                    xs: 1,
                    value: conditionalValueSelector(altDisabled, '', formData.F784AltSensorOutput2),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isDropdown: (!altDisabled),
                    isRequired: AltSystemReadingFormulaValueCheck(formData, 'AltSensorOutput', altTestPoint),
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'AC Peak', value: 'AC Peak' },
                        { name: 'AC P-P', value: 'AC P-P' },
                        { name: 'AC RMS', value: 'AC RMS' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    borderRight: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F733AltSystemReading1',
                    value: conditionalValueSelector(altDisabled, '', formData.F733AltSystemReading1),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isDropdown: (!altDisabled),
                    isRequired: AltSystemReadingFormulaValueCheck(formData, 'AltSystemReading', altTestPoint),
                    subMenu: true,
                    placeholder: placeholderUnit,
                    options: [
                        {
                            type: 'group',
                            label: '',
                            i: [{ name: placeholderUnit, value: '' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Displacement',
                            i: [
                                { name: 'mm', value: 'mm' },
                                { name: 'µm', value: 'µm' },
                                { name: 'inch', value: 'inch' },
                                { name: 'mil', value: 'mil' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Velocity',
                            i: [
                                { name: 'mm/s', value: 'mm/s' },
                                { name: 'µm/s', value: 'µm/s' },
                                { name: 'inch/s', value: 'inch/s' },
                                { name: 'mil/s', value: 'mil/s' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Acceleration',
                            i: [
                                { name: 'mm/s2', value: 'mm/s2' },
                                { name: 'µm/s2', value: 'µm/s2' },
                                { name: 'inch/s2', value: 'inch/s2' },
                                { name: 'mil/s2', value: 'mil/s2' },
                                { name: 'g', value: 'g' }
                            ]
                        },
                    ],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    borderBottom: '0',
                    textAlign: 'center',
                    xs: 1,
                },
                {
                    key: 'F785AltSystemReading1',
                    xs: 1,
                    value: conditionalValueSelector(altDisabled, '', formData.F785AltSystemReading1),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isDropdown: (!altDisabled),
                    isRequired: AltSystemReadingFormulaValueCheck(formData, 'AltSystemReading', altTestPoint),
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'Peak', value: 'Peak' },
                        { name: 'P-P', value: 'P-P' },
                        { name: 'RMS', value: 'RMS' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: 'F109ALTHMIReading1',
                    value: conditionalValueSelector(altDisabled, '', formData.F24HmiEngineeringUnit),
                    isDisabled: altDisabled,
                    isCalculatedNew: !altDisabled,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                    xs: 2,
                    readOnly: true,
                },
                {
                    key: 'F237AltDeviation1',
                    value: conditionalValueSelector(altDisabled, '', formData.F24HmiEngineeringUnit),
                    isDisabled: altDisabled,
                    isCalculatedNew: (!altDisabled),
                    type: 'number',
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                    xs: 2,
                    maxLength: '10',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                }]
        });
    if (TestPointRange(altTestPoint ?? formData?.F721NumberOfTestPoints, 10)) {
        for (let index = 2; index < Number(altTestPoint ?? formData?.F721NumberOfTestPoints) + 2; index += 1) {
            rows.push({
                key: 4 + index,
                fields: [{
                    key: index,
                    xs: 1,
                    borderTop: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderRight: '0',
                    readOnly: true,
                    isDelete: conditionalValueSelector(altDisabled, false, getAltDeleteIconStatus())
                },
                {
                    key: `AltVibrationAmplitude${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltVibrationAmplitude${index}`]),
                    isRequired: !altDisabled,
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    borderRight: '0',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    isSimulatedGapRequired: true
                },
                {
                    key: `AltVibrationFrequency${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltVibrationFrequency${index}`]),
                    isRequired: !altDisabled,
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 1,
                    maxLength: '10',
                    borderBottom: '0',
                },
                {
                    key: `AltSensorOutput${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltSensorOutput${index}`]),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                },
                {
                    key: `AltSystemReading${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltSystemReading${index}`]),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                },
                {
                    key: `AltHmiReading${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltHmiReading${index}`]),
                    isRequired: !altDisabled,
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                },
                {
                    key: `AltDeviation${index}`,
                    value: conditionalValueSelector(altDisabled, '', AsFoundTestDeviation1Formula(formData[`AltVibrationAmplitude${index}`],
                        formData[`AltHmiReading${index}`])),
                    isDisabled: altDisabled,
                    type: 'number',
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    isDeviationRequired: true
                }]
            });
        }
    }
    rows.push({
        key: 17,
        fields: [
            !altDisabled ? {
                key: 'NoData2',
                xs: 8,
                height: '0.5rem',
                value: '',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                borderBottom: '0',
                borderLeft: '0',
                marginLeft: '4px',
                borderRight: '0',
            } : {
                key: 'NoData2',
                xs: 8,
                isLabel: true,
                height: '1.1rem',
            },
            {
                key: 'AsLeftResultLabel',
                value: 'As-Left Result',
                xs: 2,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderBottom: '0',
                borderTop: conditionalValueSelector(altDisabled, '0', ''),
                borderLeft: '0',
                borderRight: '0',
                isLabelBold: true,
            },
            {
                key: 'F103AltResult',
                value: conditionalValueSelector(!altDisabled && AsLeftResultMandatoryCheck(formData, altTestPoint),
                    AsLeftResultFormula(formData, altTestPoint), ''),
                isCalculatedNew: (!altDisabled),
                isDisabled: altDisabled,
                readOnly: true,
                xs: 2,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                height: conditionalValueSelector(altDisabled, '0.8rem', '1.1rem'),
                borderRight: '0',
                inputMarginRight: '4px'
            }]
    });
    return { rows };
};
