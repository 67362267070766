import { FieldHasValue } from 'helpers/Validation';
import { commonConstants } from '../../Sections.constants';

export const test1Result = (formData) => {
    if (FieldHasValue(formData.F841AftControlValveFailSafePosition)
        && FieldHasValue(formData.F842AftSolenoidMovementUponTrip)
        && FieldHasValue(formData.F843AftControlValvePositionAfterTrip)) {
        if (formData.F842AftSolenoidMovementUponTrip === 'Quick'
            && (formData.F841AftControlValveFailSafePosition === formData.F843AftControlValvePositionAfterTrip)) {
                return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};

export const test4Result = (formData) => {
    if (FieldHasValue(formData.F846AftLimitSwitchSetting)
        && FieldHasValue(formData.F847AftControlValveMovedToLimit)
        && FieldHasValue(formData.F848AftLimitSwitchActivated)) {
        if (formData.F847AftControlValveMovedToLimit === commonConstants.Yes
            && formData.F848AftLimitSwitchActivated === commonConstants.Yes) {
                return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};
