/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from 'react';
import { del, entries, set } from 'idb-keyval';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import offlineStore from 'utils/OfflineDB';
import imageStore from 'utils/ImageDB';
import { SecondaryHeaderButton } from 'elements/Button/HeaderButton';
import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { updateMRATSyncInvalidationStatus, checkOfflineDataPresent, setCurrentView, disableMRATSyncButton } from 'journeys/portal/Home/Home.actions';
import { deleteProperty } from 'journeys/portal/Forms/Forms.actions';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

// actual MRAT sync function which traverses over items in IndexDB and makes API to post that data
export const syncMRATData = async ({ storesOfflineValues, updateInvalidationStatus, checkOfflineFormDataPresent, setCurrentAppView, disableSyncButton }) => {
    disableSyncButton(true);
    let localState = 0;
    const logException = (err) => {
        navigator.onLine && window.appInsights?.trackException({
            error: err.message,
            exception: err,
            severityLevel: SeverityLevel.Error,
            properties: { ...err }
        });
    };
    for await (const offlineData of storesOfflineValues) {
        const [uniqueKey, value] = offlineData;
        const { url } = value;
        let { body } = value;
        if (url === APIEndpoints.SubmitImages) {
            const buildFormData = (img) => {
                const formData = new FormData();
                const image = deleteProperty(img, 'base64Image');
                Object.keys(image).forEach((key) => {
                    formData.append(key, img[key]);
                });
                return formData;
            };
            body = buildFormData(body);
        } else if (url === APIEndpoints.SubmitTRFImages || url === APIEndpoints.SubmitExistingTRFImages) {
            const buildFormData = (img) => {
                const formData = new FormData();
                Object.keys(img).forEach((key) => {
                    formData.append(key, img[key]);
                });
                return formData;
            };
            body = buildFormData(body);
        }
        const syncData = (res) => {
            del(uniqueKey, offlineStore);
            localState += 1;
            updateInvalidationStatus(localState);
            checkOfflineFormDataPresent();
            url === APIEndpoints.SubmitImages && del(res.data.ImageId, imageStore).then(() => set(res.data.ImageId, res.data, imageStore));
        };
        body.RowId || body.Rowid ? await axios.put(url, body)
            .then(res => {
                syncData(res);
            })
            .catch(err => {
                logException(err);
            })
            : await axios.post(url, body)
                .then(res => {
                    syncData(res);
                })
                .catch(err => {
                    logException(err);
                });
    }
    updateInvalidationStatus(localState + 2);
    checkOfflineFormDataPresent();
    setCurrentAppView('home');
    disableSyncButton(false);
};

const MRATSync = (props) => {
    const { currentView, setCurrentAppView, invalidationStatus, updateInvalidationStatus,
        checkOfflineFormDataPresent, isOfflineDataPresent, disableSyncButton, syncButtonStatus } = props;
    const [storesOfflineValues, setStoresOfflineValues] = useState([]);
    const isMRATOnline = useSelector((state) => state.AppData.isMRATOnline);
    const shouldButtonBeVisible = currentView === 'home' || currentView === 'tagselectionmoscreen';
    const isFirstRun = useRef(true);

    /* in order to avoid calling of indexDB on every render I have placed call to indexDB inside this effect fo that
       it only checks for changes on mount and change of "shouldButtonBeVisible" i.e on change of page location */
    useEffect(() => {
        entries(offlineStore).then((localData) => {
            setStoresOfflineValues(localData);
        });
    }, [invalidationStatus, shouldButtonBeVisible, isOfflineDataPresent]);

    // this useEffect monitors "isMRATOnline" and changes in "storesOfflineValues" and if the conditions meet it tries to sync mrat data automatically
    // As useEffect was running 2 time one on render and then on change of dependancy array I fixed it to run only when dependancy array changes not on initial render
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else if (isMRATOnline && storesOfflineValues.length > 0) {
            syncMRATData({ storesOfflineValues, updateInvalidationStatus, checkOfflineFormDataPresent, setCurrentAppView, disableSyncButton });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMRATOnline, shouldButtonBeVisible]);

    if (!shouldButtonBeVisible || storesOfflineValues.length === 0) {
        return null;
    }

    return (
        <SecondaryHeaderButton
            id="mratSyncButton"
            buttonText="MRAT Sync"
            isDisabled={syncButtonStatus}
            handleButton={() => syncMRATData({ storesOfflineValues, updateInvalidationStatus, checkOfflineFormDataPresent, setCurrentAppView, disableSyncButton })}
        />
    );
};

const mapStateToProps = ({ Home }) => ({
    currentView: Home.currentView,
    invalidationStatus: Home.syncInvalidationStatus,
    isOfflineDataPresent: Home.isOfflineDataPresent,
    syncButtonStatus: Home.syncButtonStatus
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    updateInvalidationStatus: (localState) => dispatch(updateMRATSyncInvalidationStatus(localState)),
    checkOfflineFormDataPresent: () => dispatch(checkOfflineDataPresent()),
    disableSyncButton: (status) => dispatch(disableMRATSyncButton(status))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MRATSync);

MRATSync.propTypes = {
    checkOfflineFormDataPresent: PropTypes.func,
    currentView: PropTypes.string,
    disableSyncButton: PropTypes.func,
    invalidationStatus: PropTypes.number,
    isOfflineDataPresent: PropTypes.bool,
    setCurrentAppView: PropTypes.func,
    syncButtonStatus: PropTypes.bool,
    updateInvalidationStatus: PropTypes.func
};
