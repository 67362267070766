import { labelWidth } from '../HMISection/Data/P710/P710.data';

const PositionSwitchSection = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F777TypeOfPositionSwitchLabel',
                label: 'Type of Position Switch',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
                key: 'F777TypeOfPositionSwitch',
                value: formData.F777TypeOfPositionSwitch,
                isDropdown: true,
                isRequired: true,
                placeholder: 'Select Position Switch',
                options: [{ name: 'Select Position Switch', value: '' },
                { name: 'Mechanical / Contact', value: 'Mechanical / Contact' },
                { name: 'Proximity / Non-contact', value: 'Proximity / Non-contact' }],
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                inputTextAlign: 'center',
                marginBottom: '15px',
                marginTop: '15px',
                marginLeft: '0'
            },
            {
                key: 'F778PurposeOfPositionSwitchLabel',
                label: 'Purpose of Position Switch',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                labelWidth,
                textAlign: 'right',
                padding: '18px 5px 0 0'
            },
            {
                key: 'F778PurposeOfPositionSwitch',
                value: formData?.F778PurposeOfPositionSwitch,
                maxLength: '25',
                textAlign: 'center',
                marginTop: '15px',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                direction: 'rtl',
                height: '1.1rem'
            }
            ]
        }
    ]
});

export const selectPositionSwitch = (formName, formData) => {
    let filterViewData;
    if (formName === 'P8-14.1') {
        filterViewData = PositionSwitchSection(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
