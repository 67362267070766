export const bpRegionHeaderData = [
    {
        field: 'RegionId',
        headerName: 'Region ID',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'BpRegion1',
        headerName: 'BP Region',
        width: '130',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'BpRegionAlias',
        headerName: 'BP Region Alias',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Disclaimer',
        headerName: 'Disclaimer',
        width: '420',
        hideInTable: false,
        isDisabled: false,
    },
    {
        headerName: 'SAP Last Refreshed Date',
        field: 'SapLastRefreshDate',
        width: '200',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'Active',
        headerName: 'SAP Pipeline Run Status',
        width: '180',
        hideInTable: false,
        isDisabled: false,
    },
    {
        headerName: 'MRAT DPS Last Refreshed Date',
        field: 'MratDpsLastRefreshDate',
        width: '240',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'MratDpsActive',
        headerName: 'DPS Pipeline Run Status',
        width: '185',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'ModifiedBy',
        headerName: 'Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    },
];

export const bpRegionInitialState = {
    RegionId: '',
    BpRegion1: '',
    BpRegionAlias: '',
    LastRefreshDate: '',
    Disclaimer: '',
    SapLastRefreshDate: '',
    MratDpsLastRefreshDate: '',
    MratDpsActive: 'Inactive',
    Active: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const bpRegionSearchState = {
    RegionId: '',
    BpRegion1: '',
    BpRegionAlias: '',
    SapLastRefreshDate: '',
    MratDpsLastRefreshDate: '',
    MratDpsActive: '',
    Disclaimer: '',
    Active: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const bpRegionApiEndpoint = {
    read: 'ADMIN_GetBpRegionDetails'
};
