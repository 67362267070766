import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import { trackErrors } from 'journeys/portal/Admin/AdminComponents/helper';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { TableWrapper, useStyles } from './DataGridComponent.styled';
import DataGridToolbar from './Toolbar/DataGridToolbar';
import ImageViewer from './helpers/ImageViewer';
import PdfViewer from './helpers/PdfViewer';

const DataGridComponent = ({ headerData, apiEndpoint, editRecord, addRecord,
    initialData, rowDetails, refreshDatagrid, dataParser }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState(initialData);
    const [searchResult, setSearchResult] = useState([]);
    const getHeaderKeyNames = () => headerData.filter(obj => !obj.dataType)
        .map(item => ({ field: item.field, headerName: item.headerName }));
    const transformDownloadData = (temp) => temp.map(item => {
        const obj = {};
        getHeaderKeyNames().forEach(key => {
            obj[key.headerName] = item[key.field];
        });
        return obj;
    });
    const showColumns = useRef([...headerData]);
    const userRole = useSelector(({ Home }) => Home.userRole);
    const sortParam = headerData.find((key) => key.sortable === true).field;
    const dataGridWindow = document.getElementsByClassName('MuiDataGrid-window')[0];
    const searchBar = document.getElementsByClassName('targetSearchBar')[0];
    const viewOnlyRole = userRole.includes('Viewer');

    const replaceNull = (sortedData) => sortedData.map((obj) => {
        const objData = obj;
        for (const i in obj) {
            if (obj[i] === undefined || obj[i] === null) {
                objData[i] = '';
            }
        }
        return objData;
    });
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await axios.get(APIEndpoints[apiEndpoint]);
                let parsedData = [];
                if (dataParser) {
                    parsedData = [...await dataParser(res.data)];
                } else {
                    parsedData = [...res.data];
                }
                const sortedData = [...parsedData]
                    .sort((a, b) => a[sortParam].toString().localeCompare(b[sortParam].toString(), undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }));
                replaceNull(sortedData);
                const formattedData = dateFormatter(sortedData);
                setData(formattedData);
                let temp = [...formattedData];
                if (!_.isEqual(searchData, initialData)) {
                    for (const [key, value] of Object.entries(searchData)) {
                        temp = [...temp?.filter(obj => obj[key]?.toString().toLowerCase().includes(value?.toLowerCase()))];
                    }
                }
                setSearchResult(temp);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiEndpoint, refreshDatagrid]);

    useEffect(() => {
        if (dataGridWindow) {
            dataGridWindow.onscroll = () => {
                searchBar.scrollLeft = dataGridWindow.scrollLeft;
            };
        }
    });

    const handleRowDoubleClick = (dataRow) => {
        dataRow.id && editRecord(dataRow);
    };

    const handleAddRecord = () => {
        addRecord();
    };

    const handleShowColumns = (params) => {
        const index = showColumns.current.findIndex(x => x.field === params.field);
        showColumns.current[index].hideInTable = !params.isVisible;
        searchData[params.field] && handleColumnsSearch('', params.field);
    };

    const dateFormatter = (tableData) => tableData.map(item => ({
        ...item,
        CreatedDate: item.CreatedDate && formatDate(new Date(item.CreatedDate)),
        LastModifiedDate: item.LastModifiedDate && formatDate(new Date(item.LastModifiedDate)),
    }));

    const handleColumnsSearch = _.debounce((val, name) => {
        const text = val.trim();
        setLoading(true);
        let temp = [...data];
        const searchObj = { ...searchData, [name]: text };
        setSearchData((prev) => ({ ...prev, [name]: text }));
        for (const [key, value] of Object.entries(searchObj)) {
            temp = [...temp?.filter(obj => obj[key]?.toString().toLowerCase().includes(value?.toLowerCase()))];
        }
        setSearchResult(temp);
        setLoading(false);
    }, 400);
    const renderCellData = (params, key) => {
        if (key.dataType === 'image') {
            return params.value && <ImageViewer imgData={params.value} />;
        }
        if (key.dataType === 'pdf') {
            return params.value && <PdfViewer pdfData={params.value} />;
        }
        return (
            <Tooltip title={params.value?.toString() ?? ''}>
                <span className="table-cell-trucate">{params.value?.toString()}</span>
            </Tooltip>
        );
    };

    const getHeaderData = () => {
        let columnsData = [];
        columnsData = showColumns.current?.map((key) => ({
            field: key.field,
            headerName: key.headerName,
            width: key.width,
            hide: key.hideInTable,
            sortable: !key.isDisabled,
            minWidth: key.width,
            sortComparator: (v1, v2) => v1?.toString().localeCompare(v2?.toString(), undefined, {
                numeric: true,
                sensitivity: 'base'
            }),
            renderCell: (params) => renderCellData(params, key)
        }));
        return columnsData;
    };

    const getRowData = () => {
        let rowData = [];
        rowData = searchResult?.map((value, index) => ({
            id: index + 1,
            ...value,
        }));
        return rowData;
    };

    return (
        <>
            <TableWrapper>
                <DataGrid
                    components={{ Toolbar: DataGridToolbar }}
                    rows={getRowData()}
                    columns={getHeaderData()}
                    onRowDoubleClick={!viewOnlyRole && handleRowDoubleClick}
                    onColumnVisibilityChange={handleShowColumns}
                    classes={{
                        columnHeader: classes.headerRow,
                    }}
                    componentsProps={{
                        toolbar: {
                            onAddClick: () => handleAddRecord(),
                            handleColumnSearch: (text, name) => handleColumnsSearch(text, name),
                            searchFields: showColumns.current,
                            tableLoading: loading,
                            isAddVisible: !viewOnlyRole,
                            downloadResult: transformDownloadData(searchResult)
                        }
                    }}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    loading={loading}
                    disableColumnMenu
                    headerHeight={32}
                    rowHeight={27}
                    getRowClassName={(params) => params.row[rowDetails.columnName] === Number(rowDetails.rowNumber) ? 'highLight' : ''}
                />
            </TableWrapper>
        </>
    );
};

export default DataGridComponent;

DataGridComponent.propTypes = {
    addRecord: PropTypes.func,
    apiEndpoint: PropTypes.string,
    dataParser: PropTypes.func,
    editRecord: PropTypes.func,
    headerData: PropTypes.array,
    initialData: PropTypes.object,
    refreshDatagrid: PropTypes.bool,
    rowDetails: PropTypes.object
};
