import { formatDate } from 'helpers/DateFormatter';
import { conditionalValueSelector } from 'helpers/Validation';

export const handleTRFImageAndExistingForm = async ({ captureTRFImageHandler,
    updateimageNote,
    updateTRFImage,
    saveTRFImage,
    saveExistingFormOnlineOrOffline,
    clearEditimageList,
    userName, selectedTag, trfFormImage, uniqueFormName, newFormedObject, newTrfId, editedImageList }) => {
    captureTRFImageHandler(userName?.name, formatDate(selectedTag.currentDateTime)).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const isTRFImageCaptured = trfFormImage.find((data) => data.TrfId === selectedTag.TrfId.trim()
            && data.HdLocation === selectedTag.HdLocation && data.Mi === selectedTag.Mi
            && data.HdWorkOrder === selectedTag.HdWorkOrder);
        conditionalValueSelector(isTRFImageCaptured,
            () => updateTRFImage(imgData, userName, isTRFImageCaptured?.Rowid, uniqueFormName),
            () => saveTRFImage(imgData, userName, uniqueFormName))();
    });
    saveExistingFormOnlineOrOffline(uniqueFormName, newFormedObject, newTrfId, userName);
    if (editedImageList.length !== 0) {
        updateimageNote();
    }
    clearEditimageList();
};
