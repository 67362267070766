export const groupMappingHeaderData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'BpCompanyCodeName',
        headerName: 'BP Company Code Name (SAP PM)',
        width: '200',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'BpRegion',
        headerName: 'BP Region (SAP PM)',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'HdSite',
        headerName: 'HD Site (SAP PM)',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'BpSiteName',
        headerName: 'BP Site Name (SAP PM)',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'BpRegionNameAlias',
        headerName: 'BP Region Name Alias',
        width: '180',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'MratGroup',
        headerName: 'MRAT Group',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },

    {
        field: 'BpSiteAliasName',
        headerName: 'BP Site Name Alias',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'AssetImg',
        headerName: 'Asset Image',
        width: '100',
        hideInTable: false,
        isDisabled: true,
        dataType: 'image'
    },
    {
        field: 'ImageStatus',
        headerName: 'Image Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const groupMappingInitialState = {
    RowId: '',
    BpRegion: '',
    BpRegionNameAlias: '',
    BpCompanyCodeName: '',
    MratGroup: '',
    HdSite: '',
    BpSiteName: '',
    BpSiteAliasName: '',
    Status: 'NOT LIVE',
    AssetImg: null,
    ImageStatus: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const groupMappingSearchState = {
    RowId: '',
    BpRegion: '',
    BpRegionNameAlias: '',
    BpCompanyCodeName: '',
    MratGroup: '',
    HdSite: '',
    BpSiteName: '',
    BpSiteAliasName: '',
    Status: '',
    ImageStatus: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const groupMappingApiEndpoint = {
    read: 'GetMratGroupMappings'
};
