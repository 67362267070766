export const VisualInspectSectionDataP66 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Visually inspect the detector for signs of damage and corrosion. Ensure the detector has solid 
            contact with pipe and cannot be moved by hand. Check cables and glands for integrity. Verify tag identification is present and legible.`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F120Diagnostics',
            label: 'Retrieve Diagnostic Messages',
            value: formData.F120Diagnostics,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px',
        }]
    }]
});
