export const securityGroupData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'ObjectId',
        headerName: 'Object ID',
        width: '280',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Name',
        headerName: 'Name (AD Group)',
        width: '250',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'BpRegion',
        headerName: 'BP Region',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'BpRegionAlias',
        headerName: 'BP Region Alias',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Role',
        headerName: 'Role',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'AdGroupType',
        headerName: 'AD Group Type',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
        isDropdown: true,
        dropdownValues: ['Active', 'Inactive'],
        defaultValue: 'Active'
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const securityGroupInitialState = {
    RowId: '',
    ObjectId: '',
    Name: '',
    BpRegion: '',
    BpRegionAlias: '',
    RoleId: '',
    AdGroupTypeId: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Status: 'Inactive',
    Comments: '',
};

export const searchDataInitialState = {
    RowId: '',
    ObjectId: '',
    Name: '',
    BpRegion: '',
    BpRegionAlias: '',
    RoleId: '',
    AdGroupTypeId: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Status: '',
    Comments: ''
};

export const securityGroupApiEndpoint = {
    read: 'ADMIN_GetMratSecurityGroups',
    create: 'ADMIN_SubmitSecurityGroupRecord',
    update: 'ADMIN_UpdateMRATSecurityGroupRecord'
};
