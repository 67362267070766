import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MatchNumber, leadingZero } from 'helpers/Validation';
import { HandleTextValue as handleTextValue } from 'helpers/HandleTextValue';
import { InputWrapper, StyledInput } from './Inputs.styled';

export const FormInput = (props) => {
    const { id,
        label,
        type,
        name,
        value,
        handleChange,
        isRequired,
        readOnly,
        maxLength,
        formName,
        marginBottom,
        marginTop,
        height,
        fontColor,
        isLocked } = props;
    const [inputValue, handleValue] = useState(value || '');
    const isFirstRun = useRef(true);
    useEffect(() => {
        let timeOutId;
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            timeOutId = setTimeout(() => handleChange({ [name]: inputValue }), 500);
        }
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        handleValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <InputWrapper marginBottom={marginBottom} marginTop={marginTop} fontColor={fontColor}>
            <div className="label">
                {label}
            </div>
            <StyledInput
                id={id}
                formName={formName}
                type={type === 'number' ? 'tel' : 'text'}
                onWheel={type === 'number' ? (e) => e.target.blur() : null}
                onPaste={type === 'number' ? (e) => (e.clipboardData.getData('Text').includes('e')
                    || e.clipboardData.getData('Text').includes('E') || !MatchNumber(e.clipboardData.getData('Text')))
                    && e.preventDefault() : null}
                onBlur={type === 'number' ? (e) => {
                    if (!e.target.value.match(/\d/)) {
                        const val = '';
                        handleValue(val);
                    } else if (e.target.value.match(/^[-+]?\d+[.]$/)) {
                        handleValue(leadingZero(e.target.value.replace(/\.$/, '')));
                    } else {
                        handleValue(leadingZero(e.target.value));
                    }
                    return e.target.value;
                } : null}
                value={typeof inputValue === 'number' ? inputValue : inputValue?.replace(/^\s+/, '')}
                onChange={(e) => handleTextValue(e, type, handleValue, maxLength)}
                isRequired={isRequired}
                isLocked={isLocked}
                readOnly={readOnly}
                autoComplete="off"
                height={height}
            />
        </InputWrapper>
    );
};

FormInput.propTypes = {
    fontColor: PropTypes.string,
    formName: PropTypes.string,
    handleChange: PropTypes.func,
    height: PropTypes.string,
    id: PropTypes.string,
    isLocked: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.any,
    type: PropTypes.string,
    value: PropTypes.any
};
