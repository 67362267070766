import { placeholderPerformDiscard, paddingTopLeft, placeholderOpenClosed, placeholderYesNo, calculateResult } from '../AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection.data';
import { test1Result, test4Result } from './AsLeftSolenoidLimitSection.formulas';
import { commonConstants, controlValveToLouverDamper, valveToLouverDamper2 } from '../../Sections.constants';
import { conditionalValueSelector } from 'helpers/Validation';
import { asLeftSolenoid } from './Data/S71/S71.data';

export const disableTestSection = (key, disableField) => key === commonConstants.Discard || disableField;
export const disableCondition = (key, disableField, output) => disableTestSection(key) || disableField ? '' : output;
export const enableDropdownCondtion = (key, disableField) => !calculateResult(key) && !disableField;

export const asLeftSolenoidLimit = (formName, formData) => {
    const disableTest1Key = formData.F890AltTest1Solenoid;
    const disableTest4Key = formData.F895AltTest4LimitSwitch;
    const testPerform1 = conditionalValueSelector(calculateResult(formData.F836IplelementSolenoid), commonConstants.Perform, disableTest1Key);
    const testPerform2 = conditionalValueSelector(calculateResult(formData.F839IplelementLimitSwitch), commonConstants.Perform, disableTest4Key);
    const disableField = (formData.F61AsFoundResult === commonConstants.Pass || formData.F619AsLeftTestRequired === commonConstants.No);
    const controlValveToLouverDampeLabel = controlValveToLouverDamper(formName);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 4px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'attention',
                    label: `Attention: The following simple corrections may be performed by technician
                     as part of this PM work order — repeat stroking ${valveToLouverDamper2(formName)} against stiction,
                    cleaning vent lines and ports, flow regulator adjustments, limit switch adjustments.`,
                    width: '0',
                    marginLeft: '4px',
                    color: '#ea7c34',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    fontSize: '8.5pt'
                },
                ]
            },
            {
                key: 3,
                fields: [{
                    key: 'attention2',
                    label: `The following complicated corrections should be performed by technician as
                    part of follow-up corrective work order — component replacements
                    (e.g. solenoid, QEV), ${valveToLouverDamper2(formName)} and actuator overhaul, lubricating and greasing ${valveToLouverDamper2(formName)} or actuator.`,
                    width: '0',
                    marginLeft: '4px',
                    color: '#ea7c34',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    fontSize: '8.5pt'
                },
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F890AltTest1SolenoidLabel',
                        label: 'Test 1 - Solenoid',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: '14px 0 0 4px'
                    },
                    {
                        key: 'F890AltTest1Solenoid',
                        value: conditionalValueSelector(disableField, '', testPerform1),
                        isRequired: !disableField,
                        isDisabled: disableField,
                        isDropdown: enableDropdownCondtion(formData.F836IplelementSolenoid, disableField),
                        readOnly: calculateResult(formData.F836IplelementSolenoid) || disableField,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        inputTextAlign: 'center',
                        marginTop: '10px',
                        height: conditionalValueSelector((calculateResult(formData.F836IplelementSolenoid) || disableField), '1.14rem', '1.5rem'),
                    },
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                        marginBottom: '3.5px'
                    },
                    {
                        key: 'F895AltTest4LimitSwitchLabel',
                        label: 'Test 4 - Limit Switch',
                        xs: 3,
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: '14px 0 0 0',
                        labelWidth: '98%'
                    },
                    {
                        key: 'F895AltTest4LimitSwitch',
                        value: conditionalValueSelector(disableField, '', testPerform2),
                        isRequired: !disableField,
                        isDisabled: disableField,
                        isDropdown: enableDropdownCondtion(formData.F839IplelementLimitSwitch, disableField),
                        readOnly: calculateResult(formData.F839IplelementLimitSwitch) || disableField,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        marginTop: '10px',
                        borderRight: '0',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector((calculateResult(formData.F839IplelementLimitSwitch) || disableField), '1.14rem', '1.5rem'),
                    }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F891AltControlValveFailSafePositionLabel',
                    label: `${controlValveToLouverDampeLabel} Fail Safe Position`,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F891AltControlValveFailSafePosition',
                    value: disableCondition(disableTest1Key, disableField, formData.F891AltControlValveFailSafePosition),
                    isDropdown: !disableTestSection(disableTest1Key, disableField),
                    isRequired: !disableTestSection(disableTest1Key, disableField),
                    isDisabled: disableTestSection(disableTest1Key, disableField),
                    readOnly: disableTestSection(disableTest1Key, disableField),
                    xs: 2,
                    placeholder: placeholderOpenClosed,
                    options: [
                        { name: placeholderOpenClosed, value: '' },
                        { name: commonConstants.Open, value: commonConstants.Open },
                        { name: commonConstants.Closed, value: commonConstants.Closed }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    borderTop: '0',
                    height: conditionalValueSelector(disableTestSection(disableTest1Key, disableField), '1.38rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                    marginBottom: '3.5px'
                },
                {
                    key: 'F896AltLimitSwitchSettingLabel',
                    label: 'Limit Switch Setting (%)',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F896AltLimitSwitchSetting',
                    value: disableCondition(disableTest4Key, disableField, formData.F896AltLimitSwitchSetting),
                    xs: 2,
                    isRequired: !disableTestSection(disableTest4Key, disableField),
                    isDisabled: disableTestSection(disableTest4Key, disableField),
                    readOnly: disableTestSection(disableTest4Key, disableField),
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: '1.38rem',
                    borderTop: '0',
                    type: 'number',
                    maxLength: '10',
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'F892AltSolenoidMovementUponTripLabel',
                    label: 'Solenoid Movement Upon Trip',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F892AltSolenoidMovementUponTrip',
                    value: disableCondition(disableTest1Key, disableField, formData.F892AltSolenoidMovementUponTrip),
                    xs: 2,
                    isDropdown: !disableTestSection(disableTest1Key, disableField),
                    isRequired: !disableTestSection(disableTest1Key, disableField),
                    isDisabled: disableTestSection(disableTest1Key, disableField),
                    readOnly: disableTestSection(disableTest1Key, disableField),
                    placeholder: 'Select Quick/Sticking',
                    options: [
                        { name: 'Select Quick/Sticking', value: '' },
                        { name: commonConstants.Quick, value: commonConstants.Quick },
                        { name: commonConstants.Sticking, value: commonConstants.Sticking }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSection(disableTest1Key, disableField), '1.25rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F897AltControlValveMovedToLimitLabel',
                    label: `${controlValveToLouverDampeLabel} Moved to Limit`,
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F897AltControlValveMovedToLimit',
                    value: disableCondition(disableTest4Key, disableField, formData.F897AltControlValveMovedToLimit),
                    xs: 2,
                    isDropdown: !disableTestSection(disableTest4Key, disableField),
                    isRequired: !disableTestSection(disableTest4Key, disableField),
                    isDisabled: disableTestSection(disableTest4Key, disableField),
                    readOnly: disableTestSection(disableTest4Key, disableField),
                    placeholder: placeholderYesNo,
                    options: [
                        { name: placeholderYesNo, value: '' },
                        { name: commonConstants.Yes, value: commonConstants.Yes },
                        { name: commonConstants.No, value: commonConstants.No }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSection(disableTest4Key, disableField), '1.25rem', '1.5rem')
                }]
            },
            {
                key: 7,
                fields: [{
                    key: 'F893AltControlValvePositionAfterTripLabel',
                    label: `${controlValveToLouverDampeLabel} Position After Trip`,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F893AltControlValvePositionAfterTrip',
                    value: disableCondition(disableTest1Key, disableField, formData.F893AltControlValvePositionAfterTrip),
                    xs: 2,
                    isDropdown: !disableTestSection(disableTest1Key, disableField),
                    isRequired: !disableTestSection(disableTest1Key, disableField),
                    isDisabled: disableTestSection(disableTest1Key, disableField),
                    readOnly: disableTestSection(disableTest1Key, disableField),
                    placeholder: placeholderOpenClosed,
                    options: [
                        { name: placeholderOpenClosed, value: '' },
                        { name: commonConstants.Open, value: commonConstants.Open },
                        { name: commonConstants.Closed, value: commonConstants.Closed }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSection(disableTest1Key, disableField), '1.25rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F898AltLimitSwitchActivatedLabel',
                    label: 'Limit Switch Activated',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F898AltLimitSwitchActivated',
                    value: disableCondition(disableTest4Key, disableField, formData.F898AltLimitSwitchActivated),
                    xs: 2,
                    isDropdown: !disableTestSection(disableTest4Key, disableField),
                    isRequired: !disableTestSection(disableTest4Key, disableField),
                    isDisabled: disableTestSection(disableTest4Key, disableField),
                    readOnly: disableTestSection(disableTest4Key, disableField),
                    placeholder: placeholderYesNo,
                    options: [
                        { name: placeholderYesNo, value: '' },
                        { name: commonConstants.Yes, value: commonConstants.Yes },
                        { name: commonConstants.No, value: commonConstants.No }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSection(disableTest4Key, disableField), '1.25rem', '1.5rem')
                }]
            },
            {
                key: 8,
                fields: [{
                    key: 'F894AltTest1ResultLabel',
                    label: 'Test 1 Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F894AltTest1Result',
                    value: test1Result(formData),
                    xs: 2,
                    isCalculatedNew: true,
                    isDisabled: disableTestSection(disableTest1Key, disableField),
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0%',
                    marginBottom: '25px',
                    textAlign: 'center',
                    inputTextAlign: 'center'
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F899AltTest4ResultLabel',
                    label: 'Test 4 Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F899AltTest4Result',
                    value: test4Result(formData),
                    isDisabled: disableTestSection(disableTest4Key, disableField),
                    readOnly: true,
                    xs: 2,
                    isCalculatedNew: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputTextAlign: 'center',
                }]
            }
        ]
    };
};

export const selectAsLeftSolenoidLimitSectionData = (formName, formData) => {
    let filterViewData = { rows: [] };
    if (['P6-14.2', 'P7-31', 'P6-23.2', 'P6-14'].includes(formName)) {
        filterViewData = asLeftSolenoidLimit(formName, formData);
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Full') {
        filterViewData = asLeftSolenoid(formData);
    }
    return filterViewData;
};
