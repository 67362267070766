import React, { useState } from 'react';
import { Drawer, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { useStylesDrawer, AdminWrapper } from './AdminScreen.styled';
import TrfMasterList from './AdminComponents/TrfMasterList/TrfMasterList';
import { menuItems } from './AdminScreen.data';
import AdminHome from './AdminComponents/AdminHome/AdminHome';
import DpsMapping from './AdminComponents/DpsMappingDetails/DpsMapping';
import SecurityGroup from './AdminComponents/SecurityGroups/SecurityGroup';
import GroupMapping from './AdminComponents/GroupMappingDetails/GroupMapping';
import InputFieldTypes from './AdminComponents/InputFieldTypes/InputFieldTypes';
import Roles from './AdminComponents/Roles/Roles';
import UserInputType from './AdminComponents/UserInputType/UserInputType';
import AdGroupType from './AdminComponents/AdGroupType/AdGroupType';
import BpRegion from './AdminComponents/BpRegion/BpRegion';
import Dpsfield from './AdminComponents/DpsField/DpsField';
import DpsSources from './AdminComponents/DpsSources/DpsSources';
import DPSSourceMapping from './AdminComponents/DpsSourceMapping/DpsSourceMapping';
import CorrectiveDPSMapping from './AdminComponents/CorrectiveDPSMapping/CorrectiveDPSMapping';
import InformationPage from '../../../components/InformationPage/InformationPage';
import Information from './AdminComponents/Information/Information';
import MultiTrfDetails from './AdminComponents/MultiTrfDetails/MultiTrfDetails';

const AdminScreen = () => {
    const classes = useStylesDrawer();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const history = useHistory();
    const currentAppView = useSelector(({ Home }) => Home.currentView);
    const isMRATOnline = useSelector((state) => state.AppData.isMRATOnline);
    const isInfo = currentAppView === 'infoAdmin';
    const handleButtonClick = (obj, index) => {
        setSelectedIndex(index);
        history.push(`/admin/${obj.url}`);
    };
    if (!isMRATOnline) {
        return <div />;
    }
    return (
        <SiteWrapper
            headerText={isInfo ? 'Information' : 'MRAT Admin'}
            showInfoIcon={!isInfo}
        >
            {
                isInfo
                    ? <InformationPage />
                    : (
                        <>
                            <Drawer
                                className={classes.drawer}
                                variant="persistent"
                                open
                                classes={{
                                    paper: classes.drawerPaper,
                                    paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft
                                }}
                                anchor="left"
                            >
                                <List style={{ paddingTop: '10px' }}>
                                    {_.orderBy(menuItems, ['name']).map((obj, index) => (
                                        <Tooltip title={obj.dbName} key={obj.url} aria-label={obj.dbName}>
                                            <ListItem
                                                onClick={() => { handleButtonClick(obj, index); }}
                                                button
                                                key={obj.url}
                                                style={{ height: '28px' }}
                                                selected={selectedIndex === index || window.location.pathname === `/admin/${obj.url}`}
                                            >
                                                <ListItemText primary={obj.name} classes={{ primary: classes.primary }} />
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                                </List>
                            </Drawer>

                            <AdminWrapper>
                                <Switch>
                                    <Route exact path="/admin" component={AdminHome} />
                                    <Route path="/admin/trfMasterList" component={TrfMasterList} />
                                    <Route path="/admin/dpsMapping" component={DpsMapping} />
                                    <Route path="/admin/mratSecurityGroups" component={SecurityGroup} />
                                    <Route path="/admin/mratGroupMapping" component={GroupMapping} />
                                    <Route path="/admin/inputFieldTypes" component={InputFieldTypes} />
                                    <Route path="/admin/roles" component={Roles} />
                                    <Route path="/admin/userInputType" component={UserInputType} />
                                    <Route path="/admin/adGroupType" component={AdGroupType} />
                                    <Route path="/admin/bpRegion" component={BpRegion} />
                                    <Route path="/admin/dpsfield" component={Dpsfield} />
                                    <Route path="/admin/dpsDataSources" component={DpsSources} />
                                    <Route path="/admin/DPSSourceMapping" component={DPSSourceMapping} />
                                    <Route path="/admin/CorrectiveDPSMapping" component={CorrectiveDPSMapping} />
                                    <Route path="/admin/information" component={Information} />
                                    <Route path="/admin/multiTrfDetails" component={MultiTrfDetails} />
                                </Switch>
                            </AdminWrapper>
                        </>
                    )
            }
        </SiteWrapper>
    );
};

export default AdminScreen;
