import { TestPointRange } from 'helpers/Validation';
import {
    AsFoundTestDeviationFormula, AsFoundResultMandatoryCheck,
    AsFoundTestDeviation1Formula, AsFoundResultFormula, ToleranceLabelFormula, SystemReadingFormulaValueCheck
} from './P721.formulas';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsFoundTestTableSectionDataP721 = (selectedTag, formData, aftTestPoint) => {
    const rows = [];
    rows.push({
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 4,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F675NumberOfTestPoints',
            label: 'Number of Test Points',
            value: formData?.F675NumberOfTestPoints,
            isBlur: true,
            type: 'number',
            maxLength: '10',
            xs: 4,
            labelWidth: '71%',
            width: '64%',
            height: '1.26rem',
            isRequired: true,
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            readOnly: getAftDeleteIconStatus(),
            hasTestPointError: !TestPointRange(formData.F675NumberOfTestPoints, 10)
        },
        {
            key: 'F676TransducerOutputSignal',
            label: 'Transducer Output Signal',
            value: formData.F676TransducerOutputSignal,
            xs: 4,
            isDropdown: true,
            isRequired: true,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'Voltage', value: 'Voltage' },
                { name: 'Current', value: 'Current' }],
            inputTextAlign: 'center',
            labelWidth: '48%',
            width: '49.1%',
            height: '1.5rem',
            borderRight: '0',
            borderTop: '0',
            marginTop: '4px',
            marginRight: '0.5rem',
            direction: 'rtl',
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'AftDelete',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'SimulatedGap',
                label: 'Simulated Gap',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'Expected Output',
                label: 'Expected Output',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Measured Output',
                label: 'Measured Output',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'HMI Reading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'System 1 Reading',
                label: 'System 1 Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Deviation',
                label: 'Deviation',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'AftDeleteLabel',
                value: getAftDeleteIconStatus() ? 'Delete' : '',
                textAlign: 'center',
                fontColor: 'red',
                isDeleteLabel: getAftDeleteIconStatus(),
                width: '100%',
                labelWidth: '0',
                height: '1.28rem',
                xs: 1,
                borderLeft: '0',
                borderBottom: '0',
                borderRight: '0',
                readOnly: true,
                marginLeft: '4px',
            },
            {
                key: 'F665AftSimulatedGap1',
                xs: 1,
                value: formData.F665AftSimulatedGap1,
                isDropdown: true,
                isRequired: true,
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'mil', value: 'mil' },
                    { name: 'mm', value: 'mm' },
                    { name: 'µm', value: 'µm' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                borderBottom: '0',
                height: '1.65rem',
                borderRight: '0'
            },
            {
                key: 'F677AftExpectedOutput1',
                value: AsFoundTestDeviationFormula(formData),
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.4rem',
                xs: 2,
                borderBottom: '0',
                isCalculatedNew: true,
                readOnly: true,
            },
            {
                key: 'F253AftMeasuredReading1',
                value: AsFoundTestDeviationFormula(formData),
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.4rem',
                xs: 2,
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                isCalculatedNew: true,
                readOnly: true,
            },
            {
                key: 'F50HmiReading3',
                value: formData.F24HmiEngineeringUnit,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.4rem',
                xs: 2,
                borderBottom: '0',
                isCalculatedNew: true,
                readOnly: true,
            },
            {
                key: 'F691AftSystemReading1',
                value: formData.F691AftSystemReading1,
                isDropdown: true,
                isRequired: SystemReadingFormulaValueCheck(formData, 'AftSystemReading', aftTestPoint),
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'mil', value: 'mil' },
                    { name: 'mm', value: 'mm' },
                    { name: 'µm', value: 'µm' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.65rem',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                borderBottom: '0',
                xs: 2,
            },
            {
                key: 'F215AftDeviation1',
                value: AsFoundTestDeviationFormula(formData),
                type: 'number',
                isCalculatedNew: true,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.4rem',
                xs: 2,
                maxLength: '10',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
            }]
        });
    if (TestPointRange(aftTestPoint ?? formData?.F675NumberOfTestPoints, 10)) {
        for (let index = 2; index < Number(aftTestPoint ?? formData?.F675NumberOfTestPoints) + 2; index += 1) {
            rows.push({
                key: 4 + index,
                fields: [{
                    key: index,
                    xs: 1,
                    borderTop: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderRight: '0',
                    readOnly: true,
                    isDelete: getAftDeleteIconStatus()
                },
                {
                    key: `AftSimulatedGap${index}`,
                    value: formData[`AftSimulatedGap${index}`],
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    borderRight: '0',
                    xs: 1,
                    maxLength: '10',
                    borderBottom: '0',
                    isRequired: true,
                    isSimulatedGapRequired: true
                },
                {
                    key: `AftExpectedOutput${index}`,
                    value: formData[`AftExpectedOutput${index}`],
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    isRequired: true
                },
                {
                    key: `AftMeasuredReading${index}`,
                    value: formData[`AftMeasuredReading${index}`],
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                    isRequired: true
                },
                {
                    key: `AftHmiReading${index}`,
                    value: formData[`AftHmiReading${index}`],
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    isRequired: true
                },
                {
                    key: `AftSystemReading${index}`,
                    value: formData[`AftSystemReading${index}`],
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: `AftDeviation${index}`,
                    value: AsFoundTestDeviation1Formula(formData[`AftExpectedOutput${index}`],
                        formData[`AftMeasuredReading${index}`]),
                    type: 'number',
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    isDeviationRequired: true
                }]
            });
        }
    }
    rows.push({
        key: 17,
        fields: [{
            key: 'F101AftToleranceLabel',
            value: ToleranceLabelFormula(formData),
            xs: 2,
            width: '99%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginLeft: '0.26rem',
            borderLeft: '0',
            isLabelNew: true,
            marginBottom: '4px',
            marginRight: '0'
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            type: 'number',
            maxLength: '10',
            isRequired: true,
            textAlign: 'center',
            height: '1.1rem',
            borderBottom: '0',
            marginBottom: '4px'
        },
        {
            key: 'noDataAft',
            value: '',
            xs: 4,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0',
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: AsFoundResultMandatoryCheck(formData, aftTestPoint)
                ? AsFoundResultFormula(formData, aftTestPoint) : '',
            isCalculatedNew: true,
            readOnly: true,
            xs: 2,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            height: '1.1rem',
            borderRight: '0',
            marginBottom: '4px',
            inputMarginRight: '4px'
        }]
    });
    return { rows };
};
