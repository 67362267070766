import { conditionalValueSelector } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const FailureModeSectionDataP6142 = (formData) => {
    const asFoundResult = formData.F61AsFoundResult === commonConstants.Pass;
    return {
    rows: [{
        key: 1,
        fields: [{
            key: 'F619AsLeftTestRequiredLabel',
            value: 'As-Left Test Required?',
            readOnly: true,
            inputTextAlign: 'center',
            labelWidth: '0%',
            width: '100%',
            xs: 4,
            marginTop: '15px',
            textAlign: 'left',
            borderRight: '0',
            borderBottom: '0',
            isLabelNew: true,
            borderTop: '0',
            borderLeft: '0',
        },
        {
            key: 'F619AsLeftTestRequired',
            value: conditionalValueSelector(asFoundResult, '', formData.F619AsLeftTestRequired),
            isDisabled: asFoundResult,
            isRequired: false,
            isDropdown: !(asFoundResult),
            readOnly: asFoundResult,
            placeholder: 'Select Yes/No',
            options: [{ name: 'Select Yes/No', value: '' },
            { name: commonConstants.Yes, value: commonConstants.Yes },
            { name: commonConstants.No, value: commonConstants.No }],
            inputTextAlign: 'center',
            labelWidth: '0%',
            width: '100%',
            xs: 2,
            height: '1.5rem',
            marginTop: '15px',
            textAlign: 'center',
        }]
    }]
};
};
