import { homeInitialState } from './Home.data';
import { HOME_ACTIONS } from './Home.constants';

export default function HomeReducer(state = homeInitialState, action) {
    switch (action.type) {
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_FAILURE:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_PENDING:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BARRIER_TYPE_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_TEST_MAKE_MODEL_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_TAG_RECORDS_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_TAG_BI_RECORDS_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_TRF_MASTER_LISTS_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_WORK_TYPE_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_SET_WORK_TYPE_NAME:
        case HOME_ACTIONS.HOME_SET_BARRIER_TYPE_NAME:
        case HOME_ACTIONS.HOME_SET_GROUP_NAME:
        case HOME_ACTIONS.HOME_SET_ASSET_NAME:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ASSET_DATA_PENDING:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ASSET_DATA_SUCCESS:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ASSET_DATA__FAILURE:
        case HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DISCLAIMER:
        case HOME_ACTIONS.HOME_SET_USERSROLEANDREGION:
        case HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_PENDING:
        case HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_SUCCESS:
        case HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_FAILURE:
        case HOME_ACTIONS.SET_CURRENT_VIEW:
        case HOME_ACTIONS.SET_PREVIOUS_SCREEN:
        case HOME_ACTIONS.UPDATE_INVALIDATION_STATUS:
        case HOME_ACTIONS.CHECK_OFFLINE_DATA_PRESENT:
        case HOME_ACTIONS.UPDATE_SYNC_BUTTON_STATUS:
        case HOME_ACTIONS.UPDATE_BP_ASSIGNED_TRFID_LIST:
        case HOME_ACTIONS.SET_DATA_LOADED:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
