export const dpsInitialState = {
    dpsDataAll: [],
    dpsTableData: [],
    addDPSData: {},
    editDPSData: {},
    totalCount: 0,
    trfMasterList: [],
    dpsSearchString: '',
    unSavedChanges: false,
    sapFloc: '',
    sortDisplayStatusAscOrder: true,
    sortFunctionalLocationAscOrder: true,
    sortColumn: 'SapFloc',
    sapFlocExist: false,
    dpsLockedFields: [],
    correctiveDPSMapping: [],
    dpsMaxLengthMapper: {}
};

export const dpsFormState = (regionName, assetName) => ({
    Region: regionName,
    Asset: assetName,
    SapFloc: '',
    LocationDescription: '',
    Manufacturer: '',
    Model: '',
    Service: '',
    ToleranceInInstrumentEu: '',
    IcssSoftTag: '',
    DeviceType: '',
    FailureModeHighCurrentMA: '',
    FailureModeLowCurrentMA: '',
    HmiEngineeringUnits: '',
    HmiLrv: '',
    HmiUrv: '',
    InstrumentCalibratedLrv: '',
    InstrumentCalibratedUrv: '',
    InstrumentEngineeringUnits: '',
    ReCalibrationTolerance: '',
    TrfId: '',
    TrfDesc: '',
    DeckHullBldg: '',
    Quad: '',
    Level: '',
    Room: '',
    Location2: '',
    DesignElementResponseTime: '',
    TargetLeakageClass: '',
    TargetLeakageRate: '',
    HighHighSetpoint: '',
    LowLowSetpoint: '',
    FailTolerance: '',
    Span: '',
    Manufacturer2: '',
    Manufacturer3: '',
    Manufacturer4: '',
    Manufacturer5: '',
    Manufacturer6: '',
    Manufacturer7: '',
    Manufacturer8: '',
    Manufacturer9: '',
    Manufacturer10: '',
    Model2: '',
    Model3: '',
    Model4: '',
    Model5: '',
    Model6: '',
    Model7: '',
    Model8: '',
    Model9: '',
    Model10: '',
    SetPointHigh: '',
    Status: 'Active',
    Comment: '',
    F4CompletedBy: '',
    F5DateTested: '',
    F4ModifiedBy: '',
    F51DateLastModified: '',
    ReCalibrationToleranceEU: '',
    FailToleranceEU: '',
    HighHighSetpointComment: '',
    LowLowSetpointComment: '',
    CorrectiveTrfId: '',
    CorrectiveTrfDesc: '',
    Doc1Url: '',
    Doc2Url: '',
    Doc1Number: '',
    Doc2Number: '',
    ToleranceInInstrumentEUComment: '',
    LowSetpointComment: '',
    HighSetpointComment: '',
    SwitchSetpoint: '',
    SwitchSetpointComment: '',
    TargetLeakageRateEu: '',
    ValveSize: '',
    InspectionIrfId: '',
    InspectionIrfDescription: '',
    Class: '',
    GasGroup: '',
    Zone: '',
    Division: '',
    TemperatureClass: '',
    EquipmentProtectionLevel: ''
});
