import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';

const fileName = 'MRAT-Admin';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const handleExport = async (tableData) => {
    if (Array.isArray(tableData) && tableData.length > 0) {
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
};
